

interface CarrouselPanelProps {
    image: string
    active: boolean
    title: string
    subtitle: string
    pos: number
}

const CarrouselPanel: React.FC<CarrouselPanelProps> = ({ image, title, active, subtitle, pos }) => {
    return (
        <div style={{ height: active ? "90%" : "70%", padding: active ? "0 1rem" : pos < 0 ? "0 1rem 0 0" : "0 0 0 1rem" }} className="carrousel-panel">
            <div className="title">{title}</div>
            <div className="subtitle">{subtitle}</div>
            <div style={{ height: active ? "100%" : "80%" }} className="image-container">
                <img src={image} alt="" className="image" />
                <div className={active ? "background-panel green-bg" : "background-panel"} />
            </div>
        </div>
    )
}

export default CarrouselPanel