import { useContext, useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { URLS } from "../sdk/utils/consts"
import SwitchOption from "../components/SwitchOption"
import StyledTextInput from "../components/StyledTextInput"
import StyledCheckbox from "../components/StyledCheckbox"
import dayjs from "dayjs"
import { RideType } from "../sdk/utils/types"
import Ride from "../components/Ride"
import { UserContext } from "../components/UserContext"
import { Session } from "../sdk/api/requests"
import { API as LoginAPI } from '../sdk/api/endpoints/login'
import validator from 'validator';
import { PopupContext } from "../components/PopupContext"
import { API as BookingAPI } from "../sdk/api/endpoints/booking/booking"
import TripModel from "../sdk/api/models/trip"
import APIUserModel from "../sdk/api/models/api-user"
import Loading from "../components/Loading"


const Profile = () => {
    const { addPopup } = useContext(PopupContext)
    const { user, setUser } = useContext(UserContext)
    const [activeAccountView, setActiveAccountView] = useState<number>(0);
    const [newsletterCheckbox, SetnewsletterCheckbox] = useState<boolean>(false);
    const [activeRidesTab, setActiveRidesTab] = useState<number>(0)
    const [edit, setEdit] = useState<boolean>(false)
    const [pagesLoaded, setPagesLoaded] = useState<number>(1)
    const [loadingChangePassword, setLoadingChangePassword] = useState<boolean>(false)
    const [loadingUpdateProfile, setLoadingUpdateProfile] = useState<boolean>(false)
    const [loadingGetRides, setLoadingGetRides] = useState<boolean>(false)

    const [name, setName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [userRides, setUserRides] = useState<Array<TripModel>>([])

    useEffect(() => {
        getUserRides()
    }, [])

    useEffect(() => {
        if (user) {
            setName(user.name)
            setEmail(user.email)
        }
    }, [user])

    /*
        API expects to get a full user object including password, so the password provided here technically acts like a new password.
     */
    function updateProfile() {
        if (validatePassword() && validateChange()) {
            setLoadingUpdateProfile(true)
            LoginAPI.editCurrentUser({
                name: name,
                email: email,
                password: password
            }).then(() => {
                LoginAPI.getUser().then((user) => {
                    setUser(user.user)
                    localStorage.setItem('user', JSON.stringify(user))
                    Session.getUserUpdatedHandler()(user.user!)
                }).catch((err) => {
                    console.log(err)
                })
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setPassword('')
                setConfirmPassword('')
                setLoadingUpdateProfile(false)
            })
        } else {
            addPopup({
                message: 'Es wurden keine Daten veraendert',
                type: 'INFO'
            })
        }
    }

    function updatePassword() {
        if (validatePassword()) {
            setLoadingChangePassword(true)
            LoginAPI.editCurrentUser({
                name: user.name,
                email: user.email,
                password: password
            }).then(() => {
                LoginAPI.logout()
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoadingChangePassword(false)
            })
        }
    }

    function getUserRides() {
        let localStorageUser = localStorage.getItem('user')
        if (!localStorageUser) return

        let userFromData: APIUserModel = JSON.parse(localStorageUser)

        setLoadingGetRides(true)
        let userRides: Array<TripModel> = []
        BookingAPI.getBookings().then((data) => {
            data.forEach(ride => {
                if (ride.user_id === userFromData.user.id) {
                    userRides.push(ride)
                }
            })
            setUserRides(userRides)
        }).catch((err) => {
            console.log(err)
            addPopup({
                message: 'Error loading bookings',
                type: 'ERROR'
            })
        }).finally(() => {
            setLoadingGetRides(false)
        })
    }

    function validatePassword(): boolean {
        return password.trim() !== '' && confirmPassword.trim() !== '' && password === confirmPassword
    }

    function validateChange(): boolean {
        return (name.trim() !== user.name || email.trim() !== user.email) && name.trim() !== '' && email.trim() !== '' && validator.isEmail(email)
    }

    return (
        <div className="profile">
            <div className="page-header">Profile</div>
            {
                !user ?
                    <div className="not-logged-in-form-container">
                        <div className="form-container">
                            <div className="header">Sign In</div>
                            <div className="text">Enter your email to log in or create an account.</div>
                            <NavLink to={URLS.SignIn} className="button">Continue</NavLink>
                        </div>
                        <div className="form-container">
                            <div className="header">Sign Up</div>
                            <div className="text">Create account.</div>
                            <NavLink to={URLS.SignUp} className="button">Continue</NavLink>
                        </div>
                    </div> :
                    <div className="profile-container">
                        <div className="switch-container">
                            <SwitchOption fontColorActive="white" fontColor="#579485" activeColor="#579485" inactiveColor="white" border="solid 1px #579485" activeOption={activeAccountView} setActiveOption={setActiveAccountView} options={["Account", "Rides"]} />
                        </div>
                        {
                            activeAccountView === 0 ?
                                <div className="account-view">
                                    <div className="header">Contact Details</div>
                                    <div className="detail-grid">
                                        <div className="detail-row">
                                            <div className="cell">Name</div>
                                            {
                                                !edit ?
                                                    <div className="cell right">{user.name}</div>
                                                    : <input className='cell edit right' value={name} onChange={(evt) => setName(evt.target.value)} />
                                            }
                                        </div>
                                        <div className="detail-row">
                                            <div className="cell">Email</div>
                                            {
                                                !edit ?
                                                    <div className="cell right">{user.email}</div>
                                                    : <input className='cell edit right' value={email} onChange={(evt) => setEmail(evt.target.value)} />
                                            }
                                        </div>
                                        {
                                            edit ?
                                                <div className="detail-row">
                                                    <div className="cell">Password</div>
                                                    <input type="password" className='cell edit right' value={password} onChange={(evt) => setPassword(evt.target.value)} />
                                                </div> : <></>
                                        }
                                        {
                                            edit ?
                                                <div className="detail-row">
                                                    <div className="cell">Confirm Password</div>
                                                    <input type="password" className='cell edit right' value={confirmPassword} onChange={(evt) => setConfirmPassword(evt.target.value)} />
                                                </div> : <></>
                                        }
                                        <div className="detail-row">
                                            <div className="cell">Registered At</div>
                                            <div className="cell right">{dayjs(user.created_at).format('ddd, MMM DD, YYYY')}</div>
                                        </div>
                                    </div>
                                    {/* <div onClick={() => {
                                    if(edit === true) updateProfile()
                                    setEdit(!edit)
                                }} className="button">{
                                    !edit ? 'Edit' : 'Save'
                                }</div> */}
                                    {/* <div className="header">Payment</div>
                                <div className="button">Add new card</div> */}
                                    <div className="header">Change Password</div>
                                    <div className="profile-input-container">
                                        <StyledTextInput
                                            value={password}
                                            onChange={setPassword}
                                            type="password"
                                            style={{ backgroundColor: "#f5f5f5" }}
                                            placeholder="Password"
                                        >
                                            Password
                                            <span style={{ color: 'red' }}>*</span>
                                        </StyledTextInput>
                                        <StyledTextInput
                                            value={confirmPassword}
                                            onChange={setConfirmPassword}
                                            type="password" style={{ backgroundColor: "#f5f5f5" }} placeholder="Confirm password">Confirm password <span style={{ color: 'red' }}>*</span></StyledTextInput>
                                    </div>
                                    <div onClick={() => updatePassword()} className="button">Save</div>
                                    <div className="header">Notifications</div>
                                    <div className="text">Stay informed about our news and announcements!<br />You can activate and deactivate notifications below.</div>
                                    <StyledCheckbox active={newsletterCheckbox} setActive={SetnewsletterCheckbox}>
                                        <div className="checkbox-text">Newsletter</div>
                                    </StyledCheckbox>
                                    <div onClick={() => LoginAPI.logout()} className="text logout">Logout</div>
                                </div> : <></>
                        }
                        {
                            activeAccountView === 1 ?
                                <div className="rides-view">
                                    <div className="tab-switch-container">
                                        {/* <SwitchOption fontColorActive="white" fontColor="#579485" activeColor="#579485" inactiveColor="white" border="solid 1px #579485"  activeOption={activeRidesTab} setActiveOption={setActiveRidesTab} options={["Upcoming rides", "History rides"]} /> */}
                                    </div>
                                    <div className="rides-container">
                                        {
                                            loadingGetRides ?
                                                <div className="loading-container">
                                                    <Loading />
                                                </div> :
                                                userRides.map((ride, key) => {
                                                    let fullDate = dayjs(`${ride.date}T${ride.pickup_hour.toString().padStart(2, '0')}:${ride.pickup_min.toString().padStart(2, '0')}`);
                                                    return (
                                                        <div className="ride-container" key={key}>
                                                            <Ride isHistory={fullDate.isBefore(dayjs())} ride={ride} />
                                                        </div>
                                                    )
                                                })
                                        }
                                    </div>
                                </div> : <></>
                        }
                    </div>
            }
        </div>
    )
}

export default Profile

const historyRides: Array<RideType> = [
    {
        BookingNumber: 102350,
        Class: "Lorem ipsum",
        DateAndTime: dayjs(),
        PickupAddress: "Lorem ipsum",
        DropoffAdress: "Lorem ipsum",
        Distance: "10km",
        Duration: "2 hours",
        Details: "1 child car seat",
        Guest: "Lorem ipsum",
        PickupSign: "Lorem ipsum"
    }
]