import { useEffect, useState } from 'react';
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

interface StyledDatePickerProps {
	label?: string
	labelColor?: string
	backgroundColor?: string
	readOnly?: boolean
	selectedDate: dayjs.Dayjs | null
	setSelectedDate: (date: dayjs.Dayjs | null) => void
}

const StyledDatePicker = (props: StyledDatePickerProps) => {
	const [isMobile, setIsMobile] = useState<boolean>(false)

	useEffect(() => {
		const handleResize = () => {
			const screenWidth = window.innerWidth;
			const threshold = 991;

			if (screenWidth < threshold) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<div className="date-picker">
			{
				props.label ? <div className="label" style={{ color: props.labelColor + "" }}>{props.label}</div> : <></>
			}
			<DatePicker
				value={props.selectedDate}
				sx={{
					width: '100%',
					backgroundColor: props.backgroundColor,
					borderRadius: '1rem',
					"& input": {
						color: '#696969',
						padding: '1rem 1rem',
						height: '1rem',
						cursor: 'pointer',
						fontSize: 12
					},
					"& fieldset": { border: 'none' }
				}}
				onChange={(newDate) => props.setSelectedDate(newDate)}
				format={'ddd, MMM DD, YYYY'}
				readOnly={props.readOnly}
				orientation={isMobile ? 'portrait' : 'landscape'}
			/>
		</div>
	)
}

export default StyledDatePicker