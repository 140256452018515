import { useEffect, useRef, useState } from "react";
import { CarrouselPanelType } from "../../sdk/utils/types";
import CarrouselPanel from "../CarrouselPanel";


interface AnimatedImageCarrouselProps {
  classicPanels: CarrouselPanelType[]
  electricPanels: CarrouselPanelType[]
  showClassic: boolean
}

const AnimatedImageCarrousel: React.FC<AnimatedImageCarrouselProps> = ({ classicPanels, electricPanels, showClassic }) => {
  const [currentActivePanel, setCurrentActivePanel] = useState<number>(1)
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const imageContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentActivePanel > 1)
      setCurrentActivePanel(1)
  }, [showClassic])

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const threshold = 991;

      if (screenWidth < threshold) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function goNext() {
    if (showClassic)
      setCurrentActivePanel((prevIndex) => (prevIndex < classicPanels.length - 1 ? prevIndex + 1 : 0))
    else
      setCurrentActivePanel((prevIndex) => (prevIndex < electricPanels.length - 1 ? prevIndex + 1 : 0))
  }

  function goPrevious() {
    if (showClassic)
      setCurrentActivePanel((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : classicPanels.length - 1))
    else
      setCurrentActivePanel((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : electricPanels.length - 1))
  }

  function calculateMargin() {
    return !isMobile ? `calc((${(currentActivePanel - 1) * -100}%) / 3)` : `calc((${(currentActivePanel) * -100}%))`
  }

  const handleSwipe = (event: React.TouchEvent<HTMLDivElement>) => {
    const { changedTouches } = event;
    if (changedTouches.length > 0) {
      const touchStartX = changedTouches[0].clientX;
      let hasSwiped = false;

      const handleTouchMove = (event: TouchEvent) => {
        if (hasSwiped) return;

        const touchMoveX = event.changedTouches[0].clientX;
        const deltaX = touchMoveX - touchStartX;
        const threshold = 50;

        if (Math.abs(deltaX) > threshold) {
          if (deltaX > 0) {
            goPrevious();
          } else {
            goNext();
          }
          hasSwiped = true;
        }
      };

      const handleTouchEnd = () => {
        document.removeEventListener("touchmove", handleTouchMove);
        document.removeEventListener("touchend", handleTouchEnd);
      };

      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleTouchEnd);
    }
  };

  return (
    <div className="animated-image-carrousel"
      onTouchStart={handleSwipe}
      ref={imageContainerRef}
    >
      <div className="carrousel-container">
        {
          showClassic ?
            classicPanels.map((panel, key) => {
              return (
                <div style={{ marginLeft: key === 0 ? calculateMargin() : '0' }} onClick={() => key != currentActivePanel ? (key > currentActivePanel ? goNext() : goPrevious()) : {}} className="panel-container" key={key}>
                  <CarrouselPanel pos={currentActivePanel > key ? -1 : 1} title={panel.title} subtitle={panel.subtitle} image={panel.image} active={currentActivePanel === key} />
                </div>
              )
            }) :
            electricPanels.map((panel, key) => {
              return (
                <div style={{ marginLeft: key === 0 ? calculateMargin() : '0' }} onClick={() => key != currentActivePanel ? (key > currentActivePanel ? goNext() : goPrevious()) : {}} className="panel-container" key={key}>
                  <CarrouselPanel pos={currentActivePanel > key ? -1 : 1} title={panel.title} subtitle={panel.subtitle} image={panel.image} active={currentActivePanel === key} />
                </div>
              )
            })
        }
      </div>
      <div className="bubbles">
        {
          showClassic ?
            classicPanels.map((_, index) => {
              return (
                <div
                  key={index}
                  className={`bubble ${index === currentActivePanel ? "active" : ""}`}
                  onClick={() => setCurrentActivePanel(index)}
                ></div>
              )
            }) :
            electricPanels.map((_, index) => {
              return (
                <div
                  key={index}
                  className={`bubble ${index === currentActivePanel ? "active" : ""}`}
                  onClick={() => setCurrentActivePanel(index)}
                ></div>
              )
            })
        }
      </div>
    </div>
  )
}

export default AnimatedImageCarrousel