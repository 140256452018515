import ContactFormModel from "../models/contact-form";
import { BasicRequest, HTTPMethod } from "../requests";


export class API {
    public static getContactForms(startAt?: number, limit: number = 10): Promise<Array<ContactFormModel>> {
        return new BasicRequest<Array<ContactFormModel>>('/api/v1/contact-forms', HTTPMethod.GET, undefined, undefined, startAt, limit).execute()
    }

    public static postContactForm(form: Form): Promise<any> {
        return new BasicRequest<any>('/api/v1/contact-forms', HTTPMethod.POST, undefined, form).execute()
    }
}

interface Form {
    name: string
    email: string
    subject: string
    message: string
}