import { useContext, useEffect, useState } from "react"
import StyledTextInput from "../components/StyledTextInput"
import { NavLink, useNavigate } from "react-router-dom"
import { URLS } from "../sdk/utils/consts"
import { API as LoginAPI } from "../sdk/api/endpoints/login"
import { Session } from "../sdk/api/requests"
import { UserContext } from "../components/UserContext"
import { PopupContext } from "../components/PopupContext"
import validator from "validator"



const Login = () => {
    const { addPopup } = useContext(PopupContext)
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [loadingLogin, setLoadingLogin] = useState<boolean>(false)
    const { user, setUser } = useContext(UserContext)
    const navigate = useNavigate()

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                login();
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [email, password]);

    function login() {
        if (validate()) {
            setLoadingLogin(true)
            LoginAPI.login(email, password).then((user) => {
                setUser(user.user)
                Session.setSession(user.access_token)
                localStorage.setItem('user', JSON.stringify(user))
                Session.getUserUpdatedHandler()(user.user!)
                navigate(URLS.Home)
            }).catch((err) => {
                console.log(err)
                addPopup({
                    message: 'Fehler beim Login',
                    type: 'ERROR'
                })
            }).finally(() => {
                setLoadingLogin(false)
            })
        }
        else {
            console.log('form-validation error', 'Email: ' + email, 'Passwordlengt: ' + password.trim().length)
            addPopup({
                message: 'Fehler beim Login',
                type: 'ERROR'
            })
        }
    }


    function validate(): boolean {
        return (validator.isEmail(email) && password.trim() !== '')
    }

    if (user) {
        navigate(URLS.Profile)
    }

    return (
        <div className="login">
            <div className="page-header">Profile</div>
            <div className="header">Sign in</div>
            {/* <div className="text">Enter your email to log in or <NavLink to={URLS.SignUp} style={{ textDecoration: 'none', color: 'black' }}><i>create an account.</i></NavLink></div> */}
            <div className="text">
                Enter your email to log in or
                <NavLink to={URLS.SignUp} className="link-with-background">
                    create an account.
                </NavLink>
            </div>
            <div className="form-container">
                <div id="email-input" className="email-container">
                    <StyledTextInput value={email} onChange={setEmail} style={{ backgroundColor: "#f5f5f5" }} labelColor={"#121212"} placeholder="Email">Email</StyledTextInput>
                </div>
                <div id="password-input" className="password-container">
                    <StyledTextInput value={password} onChange={setPassword} type="password" style={{ backgroundColor: "#f5f5f5" }} labelColor={"#121212"} placeholder="Password">Password</StyledTextInput>
                </div>
                <div style={{ marginTop: '2.2rem' }} onClick={() => login()} className="button">Submit</div>
                {/* <div className="trennstrich">/</div>
                <div onClick={() => {}} className="button">Continue with Facebook</div> */}
            </div>
        </div>
    )
}

export default Login