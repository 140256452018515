

interface SwitchOptionProps {
    activeOption: number
    setActiveOption: (val: number) => void
    options: string[]
    label?: string
    labelColor?: string
    fontColor?: string
    fontColorActive?: string
    activeColor?: string
    inactiveColor? : string
    border?: string
}

const SwitchOption = (props: SwitchOptionProps) => {

    return (
        <div className="bool-switch">
            {
                props.label ? <div className="label" style={{ color: props.labelColor+"" }}>{props.label}</div> : <></>
            }
            <div className="option-row">
                {
                    props.options.map((value, key) => {
                        let adjustedClassName = "option-capsule"
                        if (key === 0) adjustedClassName += " first"
                        if (key === props.options.length - 1) adjustedClassName += " last"
                        if (key === props.activeOption) adjustedClassName += " active"
                        return(
                            <div style={{ color: props.activeOption === key ? props.fontColorActive : props.fontColor, background: props.activeOption === key ? props.activeColor : props.inactiveColor, border: props.border ? props.border : '' }} key={key} onClick={() => props.setActiveOption(key)} className={adjustedClassName}>{value}</div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default SwitchOption