import { Fragment, useContext, useEffect, useState } from "react";
import { ConditionalStepIconStates, ServiceClassOption } from "../sdk/utils/types";
import { CLASSIC_PANELS, ELECTRIC_PANELS, GOOGLE_PLACES_BOUNDS, URLS } from "../sdk/utils/consts";
import dayjs from "dayjs";
import { Booking } from "../sdk/api/endpoints/booking/booking";
import { Card, Checkout } from "../sdk/api/endpoints/sumup/checkout";
import { API as SumUpAPI } from '../sdk/api/endpoints/sumup/checkout'
import { API as BookingAPI } from '../sdk/api/endpoints/booking/booking'
import { API as CategoryAPI } from '../sdk/api/endpoints/category'
import { API as CustomerAPI } from '../sdk/api/endpoints/customer'
import validator from "validator";


// Components
import ConditionalStepIcon from "../components/ConditionalStepIcon";
import { CHECK_ICON, CHEVRON_UP, EQE, EQS, E_CLASS, LUGGAGE_ICON, MAYBACH, PERSON_ICON, S_CLASS, V_CLASS } from "../assets/exports";
import SwitchOption from "../components/SwitchOption";
import AnimatedImageCarrousel from "../components/Carrousels/AnimatedCarrousel";
import ServiceClassOptionPanel from "../components/ServiceClassOptionPanel";
import StyledDatePicker from "../components/StyledDatePicker";
import StyledTimePicker from "../components/StyledTimePicker";
import StyledTextInput from "../components/StyledTextInput";
import StyledSelect from "../components/StyledSelect";
import { UserContext } from "../components/UserContext";

// Assets
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { PopupContext } from "../components/PopupContext";
import CategoryModel from "../sdk/api/models/category";
import Loading from "../components/Loading";
import axios from "axios";
import CustomerModel from "../sdk/api/models/customer";

const ServiceClass = () => {
    let navigate = useNavigate()
    const uuid = require('uuid');
    const [stepStates, setStepStates] = useState<ConditionalStepIconStates[]>(['ACTIVE', 'LATER', 'LATER', 'LATER', 'LATER',]);
    const [currentStep, setCurrentStep] = useState<number>(0)
    const [farthestStepReached, setFarthestStepReached] = useState<number>(0)
    const [selectedOption, setSelectedOption] = useState<CategoryModel>()
    // const [switchState, setSwitchState] = useState<number>(0) //Controlls First Service Form Step Switch
    // const [switchStateCarrousel, setSwitchStateCarrousel] = useState<number>(0) //Controlls Carrousel
    const [formVisible, setFormVisible] = useState<boolean>(false)
    const [currentHeader, setCurrentHeader] = useState<string>("Service Class")
    const { user } = useContext(UserContext)
    const { type, date, time, pickupadress, dropoffOrHours } = useParams();
    const [loadingCategories, setLoadingCategories] = useState<boolean>(false)
    const [loadingCheckout, setLoadingCheckout] = useState<boolean>(false)
    const { addPopup } = useContext(PopupContext)
    const [categories, setCategories] = useState<Array<CategoryModel>>([])

    const [detailsSwitch, setDetailsSwitch] = useState<number>(0)
    const [pickupAddress, setPickupAddress] = useState<string>('')
    const [dropoffAddress, setDropoffAddress] = useState<string>('')
    const [toggleAutoComplete, setToggleAutoComplete] = useState<boolean>(false)
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(dayjs().add(1, 'day'))
    const [selectedTime, setSelectedTime] = useState<dayjs.Dayjs | null>(dayjs())
    const [hours, setHours] = useState<string>('')
    const [flightNumber, setFlightNumber] = useState<string>('')
    const [pickupSign, setPickupSign] = useState<string>('')
    const [notes, setNotes] = useState<string>('')

    // const [optionsCheckbox, setOptionsCheckbox] = useState<boolean>(false)
    // const [ title, setTitle ] = useState<string>('')
    const [firstName, setFirstName] = useState<string>('')
        const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [phone, setPhone] = useState<string>('')

    const [cardholderName, setCardholderName] = useState<string>('')
    const [cardNumber, setCardNumber] = useState<string>('')
    const [cardExpirationMonth, setCardExpirationMonth] = useState<string>('')
    const [cardExpirationYear, setCardExpirationYear] = useState<string>('')
    const [cardCVV, setCardCVV] = useState<string>('')

    const [googleOrigin, setGoogleOrigin] = useState<string>('')
    const [googleDestination, setGoogleDestination] = useState<string>('')
    const [loadingPrice, setLoadingPrice] = useState<boolean>(false)
    const [price, setPrice] = useState<string>('')

    const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken | null>(null);
    const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
    const [showOriginPredictions, setShowOriginPredictions] = useState<boolean>(false)
    const [showDestinationPredictions, setShowDestinationPredictions] = useState<boolean>(false)
    const [originPredictions, setOriginPredictions] = useState<Array<google.maps.places.AutocompletePrediction>>([])
    const [destinationPredictions, setDestinationPredictions] = useState<Array<google.maps.places.AutocompletePrediction>>([])

    const [prices, setPrices] = useState<string[]>([]);

    useEffect(() => {
        if (!loadingCategories) {
            const filteredCategories = categories.filter(option => option.id === 3 || option.id === 4 || option.id === 5 || option.id === 6);

            const calculateAndSetPrices = async () => {
                const updatedPrices = [];
                let kilometers = 0;
                if (pickupAddress.length > 0 && dropoffAddress.length > 0) {
                    kilometers = await calculateDistance();
                }
                for (const option of filteredCategories) {
                    try {
                        const calculatedPrice = await calculatePricePreview(option, kilometers);
                        updatedPrices.push(calculatedPrice);
                    } catch (error) {
                        console.error('Error calculating price:', error);
                    }
                }
                setPrices(updatedPrices);

            };
            calculateAndSetPrices();
        }
    }, [categories, loadingCategories]);

    // Init autocomplete session
    useEffect(() => {
        let token = new google.maps.places.AutocompleteSessionToken();
        setSessionToken(token);

        let service = new google.maps.places.AutocompleteService();
        setAutocompleteService(service);
    }, []);

    // Origin Input Handle
    useEffect(() => {
        if (pickupAddress && sessionToken && autocompleteService) {
            if (toggleAutoComplete) {
                if (originPredictions.find(prediction => prediction.description === pickupAddress) === undefined)
                    setShowOriginPredictions(true)
                setShowDestinationPredictions(false)
            }

            const options: google.maps.places.AutocompletionRequest = {
                input: pickupAddress,
                // types: ["address"],
                types: [],
                componentRestrictions: {
                    country: ['de'],
                },
                // locationRestriction: GOOGLE_PLACES_BOUNDS,
                sessionToken
            };

            autocompleteService.getPlacePredictions(options, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
                    setOriginPredictions(predictions)
                    // console.log('Origin Predictions:', predictions);
                }
            });
        }
        if (pickupAddress.trim() === '') {
            setShowOriginPredictions(false)
            setOriginPredictions([])
        }
    }, [pickupAddress, sessionToken, autocompleteService]);

    // Destination Input Handle
    useEffect(() => {
        if (dropoffAddress && sessionToken && autocompleteService) {
            if (toggleAutoComplete) {
                if (destinationPredictions.find(prediction => prediction.description === dropoffAddress) === undefined)
                    setShowDestinationPredictions(true)
                setShowOriginPredictions(false)
            }
            const options = {
                input: dropoffAddress,
                // types: ["address"],
                types: [],
                componentRestrictions: {
                    country: ['de'],
                },
                // locationRestriction: GOOGLE_PLACES_BOUNDS,
                sessionToken
            };

            autocompleteService.getPlacePredictions(options, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
                    setDestinationPredictions(predictions)
                    // console.log('Destination Predictions:', predictions);
                }
            });
        }
        if (dropoffAddress.trim() === '') {
            setShowDestinationPredictions(false)
            setDestinationPredictions([])
        }
    }, [dropoffAddress, sessionToken, autocompleteService]);

    useEffect(() => {
        if (currentStep === 4) {
            calculatePrice()
        }
    }, [currentStep])

    useEffect(() => {
        console.log(googleOrigin)
    }, [googleOrigin])

    // set default user data
    useEffect(() => {
        if (user) {
            const parts = user.name.split(" ");
            let firstName = "";
            let lastName = "";

            // if (parts.length >= 2) {
            //     if (parts[0].endsWith(".")) {
            //         firstName = parts.slice(0, 2).join(" ");
            //     } else {
            //         firstName = parts[0];
            //     }

            //     lastName = parts.slice(2).join(" ");
            // } else if (parts.length === 1) {
            //     firstName = parts[0];
            // }

            // setFirstName(firstName);
            // setLastName(lastName);
            setLastName(user.name);
            setEmail(user.email);
        }
    }, [user]);

    async function calculateDistance(): Promise<number> {
        let origin = pickupAddress.trim();
        let destination = dropoffAddress.trim();
        return new Promise<number>((resolve, reject) => {
            let service = new google.maps.DistanceMatrixService();
            const request = {
                origins: [origin],
                destinations: [destination],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
            };

            service.getDistanceMatrix(request, (data, status) => {
                if (status !== 'OK') {
                    console.log('ERROR fetching distance data');
                    reject(new Error('Google Maps Error fetching distance data'));
                } else {
                    if (data?.rows[0].elements[0].status === 'OK') {
                        const distanceValueInMeters = data!.rows[0].elements[0].distance.value;
                        const kilometers = Math.ceil(distanceValueInMeters / 1000);
                        resolve(kilometers);
                    } else {
                        reject(new Error('Google Maps Error: Distance data status is not OK'));
                    }
                }
            });
        });
    }

    async function calculatePricePreview(option: CategoryModel, kilometers: number): Promise<string> {
        try {
            let booking = {
                pickup_address: pickupAddress.trim(),
                drop_address: dropoffAddress.trim(),
                hours: hours !== '' ? hours.split(' ')[0] : '',
                categories_id: selectedOption ? selectedOption.id : undefined,
                type: detailsSwitch === 0 ? 'Transfer' : 'Hourly',
            }
            let price: number = parseFloat(option.price);
            if (booking.type === 'Hourly') {
                const hourlyPrice = parseFloat(option.hourly_price);
                const hours = parseInt(booking.hours, 10);
                price = hourlyPrice * hours;
            } else {
                if (booking.pickup_address.length > 0) {
                    // const kilometers = await calculateDistance(booking.pickup_address, booking.drop_address);
                    if (kilometers < 10) {
                        price = parseInt(option.price)
                    }
                    else {
                        const google = kilometers - 10
                        // const final_quote = google * parseFloat(option.price_per_km)
                        const final_quote = parseFloat((google * parseFloat(option.price_per_km)).toFixed(2));
                        price = final_quote + parseInt(option.price)
                    }
                }
            }
            return price.toFixed(2);
        } catch (error) {
            console.error('Error calculating price:', error);
            addPopup({
                message: 'An error occurred while calculating the price',
                type: 'ERROR',
            });
            return "";
        }
    }

    async function calculatePrice(): Promise<number> {
        setLoadingPrice(true)
        let booking: Booking = {
            pickup_address: pickupAddress.trim(),
            drop_address: dropoffAddress.trim(),
            hours: hours !== '' ? hours.split(' ')[0] : '',
            return_journey: 'false',
            categories_id: selectedOption!.id,
            pickup_sign: pickupSign !== '' ? pickupSign.trim() : 'N/A',
            flight_number: flightNumber.trim(),
            additional_info: '',
            special_request: notes.trim(),
            passagers: '1',
            seats: selectedOption!.passengers,
            bags: selectedOption!.bags,
            user_id: user.id,
            date: selectedDate!.format('YYYY-MM-DD'),
            pickup_hour: selectedTime!.format('HH'),
            pickup_min: selectedTime!.format('MM'),
            type: detailsSwitch === 0 ? 'Transfer' : 'Hourly',
            name: firstName.trim() + ' ' + lastName.trim(),
            email: email.trim(),
            phone: phone.trim().replace(/\s/g, ''),
            payment_method: 'Card'
        }

        let booking_price = 0
        let category = categories.find(cat => cat.id === booking.categories_id)!
        let discounted = parseInt(category.discounted) === 1
        let discount = parseInt(category.discount)

        if (booking.type === 'Hourly') {
            const categorie_price = parseFloat(category.hourly_price);
            const hours = parseInt(booking.hours);
            booking_price = categorie_price * hours;
        }
        else {
            const request = {
                origins: [booking.pickup_address],
                destinations: [booking.drop_address],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC
            }


            let service = new google.maps.DistanceMatrixService()
            await service.getDistanceMatrix(request, (data, status) => {
                if (status !== 'OK') {
                    setLoadingCheckout(false)
                    console.log('ERROR fetching distance data')
                    addPopup({
                        message: 'Google Maps Error fetching distance data',
                        type: 'ERROR'
                    });
                    return Promise.resolve(0)
                }

                if (data?.rows[0].elements[0].status === 'OK') {
                    setGoogleOrigin(data.originAddresses[0])
                    setGoogleDestination(data.destinationAddresses[0])
                    const distanceValueInMeters = data!.rows[0].elements[0].distance.value
                    const kilometers = Math.ceil(distanceValueInMeters / 1000)

                    if (kilometers < 10) {
                        booking_price = parseInt(category.price)
                    }
                    else {
                        const google = kilometers - 10
                        const final_quote = google * parseFloat(category.price_per_km)
                        booking_price = final_quote + parseInt(category.price)
                    }
                }
                else {
                    setLoadingCheckout(false)
                    console.log('ERROR fetching distance data')
                    addPopup({
                        message: 'Google Maps Error fetching distance data',
                        type: 'ERROR'
                    });
                }
            })
        }

        // Booking price calculated, looking for discount

        let customer: CustomerModel | undefined

        await CustomerAPI.getCustomers().then((data) => {
            customer = data.find(customer => customer.email === user.email)
        }).catch((err) => {
            console.log('ERROR', err)
        }).finally(() => {
            let real_price = booking_price
            if (discounted) {
                let new_total = booking_price
                let fp = discount / 100

                if (customer) {
                    let customerDiscount = parseInt(customer.personal_discount)
                    if (customerDiscount > 0) {
                        real_price = new_total * fp * (customerDiscount / 100)
                    }
                    else {
                        real_price = new_total * fp
                    }
                }
                else {
                    real_price = new_total * fp
                }
            }
            else {
                let new_total = booking_price

                if (customer) {
                    let customerDiscount = parseInt(customer.personal_discount)
                    if (customerDiscount > 0) {
                        real_price = new_total * (customerDiscount / 100)
                    }
                    else {
                        real_price = new_total
                    }
                }
                else {
                    real_price = new_total
                }
            }
            setPrice(parseFloat(real_price.toFixed(2)) + ' EUR')
            return Promise.resolve(real_price)
        })
        setPrice(parseFloat(booking_price.toFixed(2)) + ' EUR')
        return Promise.resolve(parseFloat(booking_price.toFixed(2)))
    }

    function handleUserEmail(): string {
        if (user) {
            return user.email.trim()
        }
        else {
            return email.trim()
        }
    }

    async function createCheckout() {
        setLoadingCheckout(true)

        let booking: Booking = {
            pickup_address: detailsSwitch === 0 ? googleOrigin : pickupAddress,
            drop_address: googleDestination,
            hours: hours !== '' ? hours.split(' ')[0] : '',
            return_journey: 'false',
            categories_id: selectedOption!.id,
            pickup_sign: pickupSign !== '' ? pickupSign.trim() : 'N/A',
            flight_number: flightNumber.trim(),
            additional_info: '',
            special_request: notes.trim(),
            passagers: '1',
            seats: selectedOption!.passengers,
            bags: selectedOption!.bags,
            user_id: user.id,
            date: selectedDate!.format('YYYY-MM-DD'),
            pickup_hour: selectedTime!.format('HH'),
            pickup_min: selectedTime!.format('MM'),
            type: detailsSwitch === 0 ? 'Transfer' : 'Hourly',
            name: firstName.trim() + ' ' + lastName.trim(),
            email: email.trim(),
            phone: phone.trim().replace(/\s/g, ''),
            payment_method: 'Card'
        }

        let card: Card = {
            card: {
                name: cardholderName.trim().toUpperCase(),
                number: cardNumber.trim().replace(/\s/g, ''),
                expiry_month: cardExpirationMonth,
                expiry_year: cardExpirationYear,
                cvv: cardCVV
            },
            payment_type: 'card'
        }

        const calculatedAmount = await calculatePrice()

        if (calculatedAmount) {
            let checkout: Checkout = {
                amount: calculatedAmount,
                checkout_reference: uuid.v4(),
                currency: 'EUR',
                // merchant_code: "M4X9HNLD",
                // merchant_code: "CCCFCKV2M",
                merchant_code: "MFFL9UGC",
                personal_details: {
                    email: email.trim(),
                    first_name: firstName.trim(),
                    last_name: lastName.trim()
                },
                // description: 'Test Description',
                description: handleUserEmail(),
                payment_type: 'card'
            }
            console.log('Checkout SUMUP started:')

            SumUpAPI.createCheckout(checkout).then((checkout) => {
                SumUpAPI.processCheckout(checkout.id, card).then((response) => {
                    storeBooking(booking)
                    addPopup({
                        message: 'Payment processed!',
                        type: 'OK'
                    })
                    // navigate('/profile')
                    console.log('SUMUP ok')
                }).catch((err) => {
                    console.log(err)
                    addPopup({
                        message: 'Payment failed to process',
                        type: 'ERROR'
                    })
                })
            }).catch((err) => {
                console.log(err)
                addPopup({
                    message: 'Failed to create checkout',
                    type: 'ERROR'
                })
            }).finally(() => {
                setLoadingCheckout(false)
            })
        }
        else {
            console.log('Error calculating price')
        }
    }

    function storeBooking(booking: Booking) {
        BookingAPI.storeBooking(booking).then(() => {
            nextStep()
        }).catch((err) => {
            console.error(err)
            addPopup({
                message: 'Failed to store booking',
                type: 'ERROR'
            })
        })
    }

    function loadCategories() {
        setLoadingCategories(true)
        CategoryAPI.getCategories().then((response) => {
            const sortedResponse = response.slice().sort((a, b) => +a.website_order - +b.website_order); // war id
            setCategories(sortedResponse)
            setSelectedOption(sortedResponse[0])
        }).catch((err) => {
            console.error('ERROR', err)
            addPopup({
                message: 'Error loading categories',
                type: 'ERROR'
            })
        }).finally(() => {
            setLoadingCategories(false)
        })
    }
    useEffect(() => {
        loadCategories()
    }, [])

    // useEffect(() => {
    //     console.log(categories);
    // }, [categories]);

    // function handleServiceSelection(id: number) {
    //     setSelectedOption(categories[id - 1])
    //     setFormVisible(true)
    // }
    function handleServiceSelection(option: any) {
        setSelectedOption(option);
        setFormVisible(true);
        console.log(option);
    }

    useEffect(() => {
        if (type !== undefined)
            // {
            setDetailsSwitch(parseInt(type!))
        // setToggleAutoComplete(false)
        // }
        if (date !== undefined)
            setSelectedDate(dayjs(date))
        if (time !== undefined)
            setSelectedTime(dayjs(time))
        if (pickupadress !== undefined)
            setPickupAddress(pickupadress!)
        if (type !== undefined && dropoffOrHours !== undefined)
            parseInt(type!) === 0 ? setDropoffAddress(dropoffOrHours!) : setHours(dropoffOrHours!)
    }, [type, date, time, pickupadress, dropoffOrHours])

    useEffect(() => {
        let tmp: Array<ConditionalStepIconStates> = []

        for (let i = 0; i < stepStates.length; i++) {
            if (i === currentStep) {
                tmp.push('ACTIVE')
            }
            else if (i < farthestStepReached) {
                tmp.push('DONE')
            }
            else tmp.push('LATER')
        }
        setStepStates(tmp)

        switch (currentStep) {
            case 0:
                setCurrentHeader("Service Class")
                break
            case 1:
                setCurrentHeader("Details")
                break
            case 2:
                setCurrentHeader("Options")
                break
            case 3:
                setCurrentHeader("Payment")
                break
            case 4:
                setCurrentHeader("Check out")
                break
        }
    }, [currentStep])

    function nextStep() {
        if (currentStep + 1 > farthestStepReached) setFarthestStepReached(farthestStepReached + 1)
        window.scrollTo(0, 0);
        setCurrentStep(currentStep + 1)
    }

    function validateStep(step: string): boolean {
        switch (step) {
            case 'Details':
                let dateflag = true
                if (selectedDate !== null) {
                    if (selectedDate.isBefore(dayjs())) {
                        addPopup({
                            message: 'Invalid date',
                            type: 'ERROR'
                        })
                        dateflag = false
                    }
                }
                else {
                    addPopup({
                        message: 'date not defined',
                        type: 'ERROR'
                    })
                    dateflag = false
                }

                let pickupflag = pickupAddress.trim() !== ''
                if (!pickupflag) {
                    addPopup({
                        message: 'Error in pickup address',
                        type: 'ERROR'
                    })
                }

                let dropoffflag = dropoffAddress.trim() !== ''
                let timeflag = selectedTime !== null

                if (detailsSwitch === 0) {
                    if (!dropoffflag) {
                        addPopup({
                            message: 'Error in dropoff address',
                            type: 'ERROR'
                        })
                    }
                }
                else {
                    if (!timeflag) {
                        addPopup({
                            message: 'Hours are not set',
                            type: 'ERROR'
                        })
                    }
                }

                let firstnameflag = firstName.trim() !== ''
                if (!firstnameflag) {
                    addPopup({
                        message: 'First Name can\'t be empty',
                        type: 'ERROR'
                    })
                }

                let lastnameflag = lastName.trim() !== ''
                if (!lastnameflag) {
                    addPopup({
                        message: 'Last Name can\'t be empty',
                        type: 'ERROR'
                    })
                }

                let emailflag = validator.isEmail(email.trim())
                if (!emailflag) {
                    addPopup({
                        message: 'Email is not valid',
                        type: 'ERROR'
                    })
                }

                let phoneflag = validator.isMobilePhone(phone.trim().replace(/\s/g, ''))
                if (!phoneflag) {
                    addPopup({
                        message: 'Phone number is not valid',
                        type: 'ERROR'
                    })
                }

                return dateflag &&
                    timeflag &&
                    pickupflag &&
                    (detailsSwitch === 0 ? dropoffflag : timeflag) &&
                    firstnameflag &&
                    lastnameflag &&
                    emailflag &&
                    phoneflag

            case 'Payment':
                let cardholdernameflag = cardholderName.trim() !== ''
                if (!cardholdernameflag) {
                    addPopup({
                        message: 'Card holder name can\'t be empty',
                        type: 'ERROR'
                    })
                }

                let cardnumberflag = cardNumber.trim() !== ''
                if (!cardnumberflag) {
                    addPopup({
                        message: 'Card number can\'t be empty',
                        type: 'ERROR'
                    })
                }

                let expirationmonthflag = cardExpirationMonth.trim() !== '' && cardExpirationMonth.length === 2
                if (!expirationmonthflag) {
                    addPopup({
                        message: 'Expiration month must be 2 digits',
                        type: 'ERROR'
                    })
                }

                let expirtaionyearflag = cardExpirationYear.trim() !== '' && cardExpirationYear.length === 4
                if (!expirtaionyearflag) {
                    addPopup({
                        message: 'Expiration year must be 4 digits',
                        type: 'ERROR'
                    })
                }

                let cvvflag = cardCVV.trim() !== ''
                if (!cvvflag) {
                    addPopup({
                        message: 'CVV can\'t be empty',
                        type: 'ERROR'
                    })
                }
                return cardholdernameflag && cardnumberflag && expirationmonthflag && expirtaionyearflag && cvvflag
            default:
                return false
        }
    }

    return (
        <div className='service-class'>
            {
                !user ?
                    <div className="no-user-overlay">
                        <div className="text">To view this page please sign in</div>
                        <NavLink to={URLS.SignIn} className="button">Sign in</NavLink>
                    </div> : <></>
            }
            <div className="page-header">
                {currentHeader}
            </div>
            <div className="content">
                {
                    !formVisible ?
                        <div className="class-select-view-1-container">
                            {/* <AnimatedImageCarrousel showClassic={switchStateCarrousel === 0} classicPanels={CLASSIC_PANELS} electricPanels={ELECTRIC_PANELS} />
                            <div className="booking-row-section">
                                <div className="booking-row">
                                    <div className="flip-container">
                                        <SwitchOption activeOption={switchStateCarrousel} setActiveOption={setSwitchStateCarrousel} fontColorActive="white" fontColor="#579485" activeColor="#579485" options={['Electric', 'Classic']} />
                                    </div>
                                    <div onClick={() => setFormVisible(true)} className="booking-button">Book</div>
                                </div>
                            </div> */}
                            <div className="service-class-options-list">
                                {
                                    loadingCategories ?
                                        <div className="loading-container">
                                            <Loading />
                                        </div>
                                        :
                                        categories
                                            .filter(option => option.id === 3 || option.id === 4 || option.id === 5 || option.id === 6) // nur luxury, first, business und van class
                                            .map((option, key) => {
                                                const indexInCategories = categories.filter(option => option.id === 3 || option.id === 4 || option.id === 5 || option.id === 6).findIndex(cat => cat.id === option.id);
                                                if (indexInCategories !== -1) {
                                                    console.log(prices)
                                                    console.log(indexInCategories)
                                                    let usePrice;
                                                    if (prices.length > indexInCategories) {
                                                        usePrice = prices[indexInCategories]
                                                    }
                                                    return (
                                                        <div key={key} className="service-class-options-container">
                                                            <ServiceClassOptionPanel
                                                                id={option.id}
                                                                image={serviceClassOptionsDetails.find(matchOption => matchOption.id === option.id)!.image}
                                                                name={option.name}
                                                                subtitle={option.make + ' ' + option.model}
                                                                maxPeople={option.passengers}
                                                                maxLuggage={option.bags}
                                                                bulletpoints={serviceClassOptionsDetails.find(matchOption => matchOption.id === option.id)!.bulletpoints}
                                                                cancelationPolicyText={serviceClassOptionsDetails.find(matchOption => matchOption.id === option.id)!.cancelationPolicyText}
                                                                // price={option.price}
                                                                price={usePrice ?? 'Loading...'}
                                                                onClick={() => handleServiceSelection(option)}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            })
                                }
                            </div>

                        </div> :
                        <div className="class-select-view-2-container">
                            {
                                currentStep !== 5 ?
                                    <div className="step-row">
                                        <div className="step-container">
                                            <div className="icon-container">
                                                <ConditionalStepIcon
                                                    active={0 <= farthestStepReached}
                                                    onClick={() => setCurrentStep(0)}
                                                    state={stepStates[0]}
                                                />
                                            </div>
                                            <div className="step-title">Service Class</div>
                                        </div>
                                        <img src={CHEVRON_UP} alt="" className="chevron-right" />
                                        <div className="step-container">
                                            <div className="icon-container">
                                                <ConditionalStepIcon
                                                    active={1 <= farthestStepReached}
                                                    onClick={() => setCurrentStep(1)}
                                                    state={stepStates[1]}
                                                />
                                            </div>
                                            <div className="step-title">Details</div>
                                        </div>
                                        <img src={CHEVRON_UP} alt="" className="chevron-right" />
                                        <div className="step-container">
                                            <div className="icon-container">
                                                <ConditionalStepIcon
                                                    active={2 <= farthestStepReached}
                                                    onClick={() => setCurrentStep(2)}
                                                    state={stepStates[2]}
                                                />
                                            </div>
                                            <div className="step-title">Options</div>
                                        </div>
                                        <img src={CHEVRON_UP} alt="" className="chevron-right" />
                                        <div className="step-container">
                                            <div className="icon-container">
                                                <ConditionalStepIcon active={3 <= farthestStepReached} onClick={() => setCurrentStep(3)} state={stepStates[3]} />
                                            </div>
                                            <div className="step-title">Payment</div>
                                        </div>
                                        <img src={CHEVRON_UP} alt="" className="chevron-right" />
                                        <div className="step-container">
                                            <div className="icon-container">
                                                <ConditionalStepIcon active={4 <= farthestStepReached} onClick={() => setCurrentStep(4)} state={stepStates[4]} />
                                            </div>
                                            <div className="step-title">Check out</div>
                                        </div>
                                    </div> : <></>
                            }
                            {
                                currentStep === 0 ?
                                    <div className="class-select-view-2">
                                        <div className="header">Service Class</div>
                                        <div className="view-container">
                                            <div className="class-select-button-container">
                                                <div className="label">Select Class</div>
                                                <div onClick={() => setFormVisible(false)} className="button">{selectedOption!.name}</div>
                                            </div>
                                            <div className="option-details">
                                                <div className="header-row">
                                                    <div className="labels">
                                                        <div className="name">{selectedOption!.name}</div>
                                                        <div className="subtitle">{selectedOption!.make + ' ' + selectedOption!.model}</div>
                                                    </div>
                                                    <div className="icons">
                                                        <div className="detail-icon">
                                                            <div className="icon-container">
                                                                <img src={PERSON_ICON} alt="" className="icon" />
                                                            </div>
                                                            <div className="text">max.&nbsp;{selectedOption!.passengers}</div>
                                                        </div>
                                                        <div className="detail-icon">
                                                            <div className="icon-container">
                                                                <img src={LUGGAGE_ICON} alt="" className="icon" />
                                                            </div>
                                                            <div className="text">max.&nbsp;{selectedOption!.bags}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="car-image-container">
                                                    <img src={serviceClassOptionsDetails.find(matchOption => matchOption.id === selectedOption!.id)!.image} alt="" className="car" />
                                                    <div className="square" />
                                                </div>
                                                {
                                                    serviceClassOptionsDetails.find(matchOption => matchOption.id === selectedOption!.id)!.cancelationPolicyText ?
                                                        <div className="cancelation-container">
                                                            Cancelation policy
                                                            {
                                                                serviceClassOptionsDetails.find(matchOption => matchOption.id === selectedOption!.id)!.cancelationPolicyText.map((text, key) => {
                                                                    return (
                                                                        <div key={key} className="bulletpoint">{text}</div>
                                                                    )
                                                                })
                                                            }
                                                        </div> : <></>
                                                }
                                                <div onClick={() => nextStep()} className="select-button">Select</div>
                                            </div>
                                        </div>
                                    </div> : <></>
                            }
                            {
                                currentStep === 1 ?
                                    <div className="details-view">
                                        <div className="header">Airport pickup details</div>
                                        <div className="form-container">
                                            <div className="switch-container">
                                                <SwitchOption
                                                    activeOption={detailsSwitch}
                                                    setActiveOption={setDetailsSwitch}
                                                    fontColorActive="white"
                                                    fontColor="#579485"
                                                    activeColor="#579485"
                                                    inactiveColor="white"
                                                    border="solid 1px #579485"
                                                    options={["Transfer", "Hourly"]}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="input-container">
                                                    <StyledDatePicker
                                                        selectedDate={selectedDate}
                                                        setSelectedDate={setSelectedDate}
                                                        backgroundColor="#f5f5f5"
                                                        label={"Date"}
                                                        labelColor={"#121212"}
                                                    />
                                                </div>
                                                <div className="input-container">
                                                    <StyledTimePicker
                                                        selectedTime={selectedTime}
                                                        setSelectedTime={setSelectedTime}
                                                        backgroundColor="#f5f5f5"
                                                        label={"Time"}
                                                        labelColor={"#121212"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="text-input-container">
                                                <StyledTextInput
                                                    value={pickupAddress}
                                                    onChange={setPickupAddress}
                                                    onTextChange={(text) => setToggleAutoComplete(true)}
                                                    style={{
                                                        backgroundColor: "#f5f5f5",
                                                        borderRadius: showOriginPredictions ? '1rem 1rem 0 0' : ''
                                                    }}
                                                    labelColor={"#121212"}
                                                    placeholder="Address, airport"
                                                >
                                                    Pickup Address
                                                </StyledTextInput>
                                                {
                                                    showOriginPredictions ?
                                                        <div className="predictions-container">
                                                            {
                                                                originPredictions.map((prediction, key) => {
                                                                    return (
                                                                        <div
                                                                            onClick={() => {
                                                                                setPickupAddress(prediction.description)
                                                                                setShowOriginPredictions(false)
                                                                            }}
                                                                            key={key}
                                                                            className="prediction"
                                                                        >
                                                                            {prediction.description}
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div> : <></>
                                                }
                                            </div>
                                            {
                                                detailsSwitch === 0 ?
                                                    <div className="text-input-container">
                                                        <StyledTextInput
                                                            value={dropoffAddress}
                                                            onChange={setDropoffAddress}
                                                            onTextChange={(text) => setToggleAutoComplete(true)}
                                                            style={{
                                                                backgroundColor: "#f5f5f5",
                                                                borderRadius: showDestinationPredictions ? '1rem 1rem 0 0' : ''
                                                            }}
                                                            labelColor={"#121212"}
                                                            placeholder="Address, airport"
                                                        >
                                                            Dropoff Address
                                                        </StyledTextInput>
                                                        {
                                                            showDestinationPredictions ?
                                                                <div className="predictions-container">
                                                                    {
                                                                        destinationPredictions.map((prediction, key) => {
                                                                            return (
                                                                                <div className="prediction"
                                                                                    key={key}
                                                                                    onClick={() => {
                                                                                        setDropoffAddress(prediction.description)
                                                                                        setShowDestinationPredictions(false)
                                                                                    }}  >
                                                                                    {prediction.description}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div> : <></>
                                                        }
                                                    </div>
                                                    : <StyledSelect
                                                        inputColor='#696969'
                                                        backgroundColor="#f5f5f5"
                                                        labelColor={"#121212"}
                                                        options={hourOptions}
                                                        placeholder="hours"
                                                        value={hours}
                                                        setValue={setHours}
                                                    >
                                                        Hours
                                                    </StyledSelect>
                                            }
                                            <StyledTextInput
                                                value={firstName}
                                                onChange={setFirstName}
                                                style={{ backgroundColor: '#f5f5f5' }}
                                                labelColor={"#121212"}
                                                placeholder="First Name"
                                                                                        >
                                                First Name
                                            </StyledTextInput>
                                            <StyledTextInput
                                                value={lastName}
                                                onChange={setLastName}
                                                style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="Last Name"
                                            >
                                                Last Name
                                            </StyledTextInput>
                                            <StyledTextInput
                                                value={email}
                                                onChange={setEmail}
                                                style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="Email"
                                                                                            >
                                                Email
                                            </StyledTextInput>
                                            <StyledTextInput
                                                value={phone}
                                                onChange={setPhone}
                                                style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="+1 (000) 000-0000">
                                                Mobile Number
                                            </StyledTextInput>
                                            <div className="text">
                                                Please enter the phone number on which the guest would like to receive notifications
                                            </div>
                                            <div
                                                onClick={() => validateStep('Details') ? nextStep() : {}}
                                                className="button"
                                            >
                                                Continue
                                            </div>
                                        </div>
                                    </div> : <></>
                            }
                            {
                                currentStep === 2 ?
                                    <div className="options-view">
                                        <div className="header">Options</div>
                                        <div className="form-container">
                                            <StyledTextInput
                                                value={flightNumber}
                                                onChange={setFlightNumber}
                                                style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="LH123">
                                                Flight Number
                                            </StyledTextInput>
                                            <StyledTextInput
                                                value={pickupSign}
                                                onChange={setPickupSign}
                                                style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="Pickup Sign">
                                                Pickup Sign
                                            </StyledTextInput>
                                            <StyledTextInput
                                                value={notes}
                                                onChange={setNotes}
                                                style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="Child car seats, …">
                                                Notes / Special requests
                                            </StyledTextInput>
                                            <div className="text">
                                                Add special requests or relevant info, e.g. number of bags, itinerary for an hourly booking, etc. Please do not include confidential information.
                                            </div>
                                            {/* <div className="checkbox-container">
                                                <StyledCheckbox active={optionsCheckbox} setActive={setOptionsCheckbox}>
                                                    <div className="checkbox-text">Is the booking made for someone else?</div>
                                                </StyledCheckbox>
                                            </div> */}
                                            <div className="switch-buttons">
                                                <div onClick={() => nextStep()} className="switch-button left">Continue</div>
                                                <div onClick={() => {
                                                    setFlightNumber('')
                                                    setPickupSign('')
                                                    setNotes('')
                                                    nextStep()
                                                }} className="switch-button right">Skip</div>
                                            </div>
                                        </div>
                                    </div> : <></>
                            }
                            {
                                currentStep === 3 ?
                                    <div className="payment-view">
                                        <div className="header">Payment</div>
                                        <div className="input-container">
                                            {/* <StyledTextInput
                                                value={price}
                                                setValue={setPrice}
                                                readOnly style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="price">
                                                Price
                                            </StyledTextInput> */}
                                            <StyledTextInput
                                                value={cardholderName}
                                                onChange={setCardholderName}
                                                style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="Cardholder name"
                                            >
                                                Cardholder name
                                            </StyledTextInput>
                                            <StyledTextInput
                                                type="creditcard"
                                                value={cardNumber}
                                                onChange={setCardNumber}
                                                style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="Card number">
                                                Card number
                                            </StyledTextInput>
                                            <div className="expiration-date-label">Expiration date / CVV</div>
                                            <div className="expiration-date-input-row">
                                                <input
                                                    value={cardExpirationMonth}
                                                    onChange={(evt) => evt.target.value.length <= 2 ? setCardExpirationMonth(evt.target.value) : {}}
                                                    placeholder="MM"
                                                    type="number"
                                                    className="expiration-date-input"
                                                />
                                                <input
                                                    value={cardExpirationYear}
                                                    onChange={(evt) => evt.target.value.length <= 4 ? setCardExpirationYear(evt.target.value) : {}}
                                                    placeholder="YYYY"
                                                    type="text"
                                                    className="expiration-date-input" />
                                                <input
                                                    value={cardCVV}
                                                    onChange={(evt) => setCardCVV(evt.target.value)}
                                                    placeholder="CVV"
                                                    type="text"
                                                    className="expiration-date-input"
                                                />
                                            </div>
                                            <div className="switch-buttons">
                                                <div onClick={() => validateStep('Payment') ? nextStep() : {}} className="switch-button left">Continue</div>
                                                <div onClick={() => navigate(URLS.Home)} className="switch-button right">Cancel</div>
                                            </div>
                                            <div className="disclaimer">
                                                Please note: You will be able to review your ride on the next page before confirming your reservation. After you have placed your booking, we will temporarily reserve the funds on your card with a pre-authorization to guarantee your ride. Your card is only charged after the ride has taken place.
                                            </div>
                                        </div>
                                    </div> : <></>
                            }
                            {
                                currentStep === 4 ?
                                    <div className="checkout-view">
                                        <div className="header">Checkout</div>
                                        <div className="checkout-content">
                                            <div className="icon-container">
                                                <img src={CHECK_ICON} alt="" className="icon" />
                                            </div>
                                            <div className="row">
                                                <div className="input-container">
                                                    <StyledDatePicker
                                                        selectedDate={selectedDate}
                                                        setSelectedDate={setSelectedDate}
                                                        readOnly
                                                        backgroundColor="#f5f5f5"
                                                        label={"Date"}
                                                        labelColor={"#121212"}
                                                    />
                                                </div>
                                                <div className="input-container">
                                                    <StyledTimePicker
                                                        selectedTime={selectedTime}
                                                        setSelectedTime={setSelectedTime}
                                                        readOnly
                                                        backgroundColor="#f5f5f5"
                                                        label={"Time"}
                                                        labelColor={"#121212"}
                                                    />
                                                </div>
                                            </div>
                                            <StyledTextInput
                                                value={detailsSwitch === 0 ? googleOrigin : pickupAddress}
                                                onChange={setPickupAddress}
                                                readOnly
                                                style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="Address, airport"
                                            >
                                                Pickup Address
                                            </StyledTextInput>
                                            {
                                                detailsSwitch === 0 ?
                                                    <StyledTextInput
                                                        value={googleDestination}
                                                        onChange={setDropoffAddress}
                                                        readOnly
                                                        style={{ backgroundColor: "#f5f5f5" }}
                                                        labelColor={"#121212"}
                                                        placeholder="Address, airport"
                                                    >
                                                        Dropoff Address
                                                    </StyledTextInput>
                                                    : <StyledSelect
                                                        readOnly
                                                        inputColor='#696969'
                                                        backgroundColor="#f5f5f5"
                                                        labelColor={"#121212"}
                                                        options={hourOptions}
                                                        placeholder="hours"
                                                        value={hours}
                                                        setValue={setHours}
                                                    >
                                                        Hours
                                                    </StyledSelect>
                                            }
                                            <StyledTextInput
                                                value={flightNumber.trim() === '' ? 'N/A' : flightNumber.trim()}
                                                onChange={setFlightNumber}
                                                readOnly
                                                style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"} placeholder="LH123"
                                            >
                                                Flight Number
                                            </StyledTextInput>
                                            <StyledTextInput
                                                value={pickupSign.trim() === '' ? 'N/A' : pickupSign.trim()}
                                                onChange={setPickupSign}
                                                readOnly
                                                style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="Name"
                                            >
                                                Pickup sign name
                                            </StyledTextInput>
                                            <StyledTextInput
                                                value={notes.trim() === '' ? 'N/A' : notes.trim()}
                                                onChange={setNotes}
                                                readOnly
                                                style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="Child car seats, …"
                                            >
                                                Notes / Special requests
                                            </StyledTextInput>
                                            {/* <StyledSelect value={title} setValue={setTitle} readOnly style={{ backgroundColor: "#f5f5f5" }} options={["Mr.", "Ms.", "Mx."]} placeholder="Select (Mr. Ms. Mx.)"> Title</StyledSelect> */}
                                            <StyledTextInput
                                                value={firstName}
                                                onChange={setFirstName}
                                                readOnly
                                                style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="First Name"
                                            >
                                                First Name
                                            </StyledTextInput>
                                            <StyledTextInput
                                                value={lastName}
                                                onChange={setLastName}
                                                readOnly
                                                style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="Last Name"
                                            >
                                                Last Name
                                            </StyledTextInput>
                                            <StyledTextInput
                                                value={email}
                                                onChange={setEmail}
                                                readOnly style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="Email">
                                                Email
                                            </StyledTextInput>
                                            <StyledTextInput
                                                value={phone}
                                                onChange={setPhone}
                                                readOnly
                                                style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="+1 (000) 000-0000">
                                                Mobile Number
                                            </StyledTextInput>
                                            <StyledTextInput
                                                // value={price}
                                                value={parseFloat(price).toFixed(2) + ' EUR'}
                                                onChange={setPrice}
                                                readOnly style={{ backgroundColor: "#f5f5f5" }}
                                                labelColor={"#121212"}
                                                placeholder="price">
                                                Price
                                            </StyledTextInput>
                                            <div className="switch-buttons">
                                                <div className="switch-button left"
                                                    onClick={() => !loadingCheckout ? createCheckout() : {}}
                                                >
                                                    {
                                                        loadingCheckout ?
                                                            <div className="loading-container">
                                                                <Loading />
                                                            </div> :
                                                            <Fragment>
                                                                Confirm and pay
                                                            </Fragment>
                                                    }</div>
                                                <NavLink to={URLS.Home} className="switch-button right">Home</NavLink>
                                            </div>
                                        </div>
                                    </div> : <></>
                            }
                            {
                                currentStep === 5 ?
                                    <div className="checkout-view">
                                        <div className="header">Thank you!</div>
                                        <div className="checkout-content">
                                            <div className="icon-container">
                                                <img src={CHECK_ICON} alt="" className="icon" />
                                            </div>
                                        </div>
                                    </div> : <></>
                            }
                        </div>
                }
            </div >
        </div >
    )
}

export default ServiceClass
const serviceClassOptionsDetails: Array<{
    id: number
    name: string
    image: string
    bulletpoints: string[]
    cancelationPolicyText: string[]
}> =
    [
        {
            id: 1,
            name: "First Class",
            // image: S_CLASS,
            image: EQS,
            // bulletpoints: [
            //     "Elegance meets Confort",
            //     "Child Seats Available Upon Request",
            //     "Bottled Water included",
            // ],
            bulletpoints: [
                "Eco - Friendly Efficiency.",
                "No Compromise on Comfort.",
                "Child Seats Available Upon Request"
            ],
            cancelationPolicyText: [
                "Free cancelation 1h before pickup",
                "Free waiting time 1h",
                "Minimum 2h before pickup for bookings",
            ]
        },
        {
            id: 2,
            // image: E_CLASS,
            image: EQE,
            name: "Business Class",
            // bulletpoints: [
            //     "Professional and Versatile",
            //     "Child Seats Available Upon Request",
            //     "Bottled Water included",
            // ],
            bulletpoints: [
                "Sustainable Luxury.",
                "Quiet, Spacious, and Eco-Conscious.",
                "Child Seats Available Upon Request",
            ],
            cancelationPolicyText: [
                "Free cancelation 1h before pickup",
                "Free waiting time 1h",
                "Minimum 2h before pickup for bookings",
            ]
        },
        {
            id: 3,
            image: MAYBACH,
            name: "Luxury Class",
            bulletpoints: [
                "The Pinnacle of Luxury — Perfect for Unforgettable Moments",
            ],
            cancelationPolicyText: [
                "Free cancelation 1h before pickup",
                "Free waiting time 1h",
                "Minimum 2h before pickup for bookings",
            ]
        },
        // {
        //     id: 4,
        //     image: B_CLASS,
        //     name: "City Class",
        //     bulletpoints: [
        //         "No Compromise on Comfort.",
        //         "Child Seats Available Upon Request"
        //     ],
        //     cancelationPolicyText: [
        //         "Free cancelation 1h before pickup",
        //         "Free waiting time 1h",
        //         "Minimum 2h before pickup for bookings",
        //     ]
        // },
        {
            id: 5,
            image: V_CLASS,
            name: "XL Class",
            bulletpoints: [
                "Room to Breathe",
                "Spacious Luggage Capacity",
                "Perfect for Large Groups or Families",
            ],
            cancelationPolicyText: [
                "Free cancelation 1h before pickup",
                "Free waiting time 1h",
                "Minimum 2h before pickup for bookings",
            ]
        },
        {
            id: 4,
            image: EQE,
            name: "Electric Class",
            bulletpoints: [
                "Eco - Friendly Efficiency.",
                "No Compromise on Comfort.",
                "Child Seats Available Upon Request"
            ],
            cancelationPolicyText: [
                "Free cancelation 1h before pickup",
                "Free waiting time 1h",
                "Minimum 2h before pickup for bookings",
            ]
        },
        {
            id: 6,
            image: EQS,
            name: "First class electric",
            bulletpoints: [
                "Sustainable Luxury.",
                "Quiet, Spacious, and Eco-Conscious.",
                "Child Seats Available Upon Request",
            ],
            cancelationPolicyText: [
                "Free cancelation 1h before pickup",
                "Free waiting time 1h",
                "Minimum 2h before pickup for bookings",
            ]
        }
    ]

const hourOptions = [
    "2 hours",
    "3 hours",
    "4 hours",
    "5 hours",
    "6 hours",
    "7 hours",
    "8 hours",
    "9 hours",
    "10 hours",
    "11 hours",
    "12 hours",
    "13 hours",
    "14 hours",
    "15 hours",
    "16 hours",
    "17 hours",
    "18 hours",
    "19 hours",
    "20 hours",
    "21 hours",
    "22 hours",
    "23 hours",
    "24 hours"
]
