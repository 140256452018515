import { useState } from "react"
import { LUGGAGE_ICON, PERSON_ICON } from "../assets/exports"
import { ServiceClassOption } from "../sdk/utils/types"
import SwitchOption from "./SwitchOption"


interface ServiceClassOptionPanelProps extends ServiceClassOption {
    switchState?: boolean
    onClick: (val: number) => void //tmp cause we dont know the flow yet
}

const ServiceClassOptionPanel = (props: ServiceClassOptionPanelProps) => {
    const [switchState, setSwitchState] = useState<number>(0)

    return (
        <div className="service-class-option-panel">
            <div className="car-square-container">
                <img src={props.image} alt="" className="car" />
                {
                    props.switchState ?
                        <div className="switch-container">
                            <SwitchOption activeOption={switchState} setActiveOption={setSwitchState} options={["Electric", "Classic"]} />
                        </div> : <></>
                }
            </div>
            <div className="detail-container">
                <div className="header-row">
                    <div className="labels">
                        <div className="name">{props.name}</div>
                        <div className="subtitle">{props.subtitle}</div>
                    </div>
                    <div className="detail-icon-row">
                        <div className="detail-icon-container">
                            <img src={PERSON_ICON} alt="" className="icon" />
                            <div className="text">max.&nbsp;{props.maxPeople}</div>
                        </div>
                        <div className="detail-icon-container">
                            <img src={LUGGAGE_ICON} alt="" className="icon" />
                            <div className="text">max.&nbsp;{props.maxLuggage}</div>
                        </div>
                    </div>
                    <div className="labels price">
                        <div className="name">{props.price} EUR</div>
                        <div className="subtitle">incl. VAT & fees</div>
                    </div>
                </div>
                <div className="bulletpoints-list">
                    {
                        props.bulletpoints.map((text, key) => {
                            return (
                                <div key={key} className="bulletpoint-container"><div className="square" />{text}</div>
                            )
                        })
                    }
                </div>
                <div className="bottom-row">
                    <div className="cancelation-policy-container">
                        {/* {
                            props.cancelationPolicyText ? 
                            <div className="title">Cancelation Policy</div>:<></>
                        } */}
                        {
                            props.cancelationPolicyText ? props.cancelationPolicyText.map((text, key) => {
                                return (
                                    <div key={key} className="text">{text}</div>
                                )
                            }) : <></>
                        }
                    </div>
                    <div onClick={() => props.onClick(props.id)} className="booking-button">Book</div>
                </div>
            </div>
        </div>
    )
}

export default ServiceClassOptionPanel