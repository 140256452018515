import { ReactNode, useState } from "react"


interface StyledCheckboxProps {
    children: ReactNode
    active: boolean
    setActive: (val: boolean) => void
}

const StyledCheckbox = ( props: StyledCheckboxProps ) => {

    return (
        <div onClick={() => props.setActive(!props.active)} className="styled-checkbox">
            <div style={{ backgroundColor: props.active ? '#579485' : '#f5f5f5', border: props.active ? 'none' : '1px #707070 solid' }} className="box" />
            {props.children}
        </div>
    )
}

export default StyledCheckbox