import { ConditionalStepIconStates } from "../sdk/utils/types"


// Assets
import { CHECK_ICON } from "../assets/exports"


interface ConditionalStepIconProps {
    state: ConditionalStepIconStates
    onClick: () => void
    active: boolean
}

const ConditionalStepIcon = ( props: ConditionalStepIconProps ) => {


    return (
        <div onClick={() => props.active ? props.onClick() : {}} style={{ backgroundColor: props.state === 'ACTIVE' ? '#579485' : props.state === 'LATER' ? '#ebebeb' : '', borderColor: props.state === 'LATER' ? '#ebebeb' : '#579485', cursor: props.active ? 'pointer' : 'default' }} className="conditional-step-icon">
            {
                props.state === 'DONE' ? 
                <img src={CHECK_ICON} alt="" className="icon" /> : <></>
            }
        </div>
    )
}

export default ConditionalStepIcon