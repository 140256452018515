const TermsAndConditions = () => {
    // window.scrollTo(0, 0)
    return (
        <div className="terms-and-conditions">
            <div className="page-header">Terms and Conditions</div>
            <div className="content">
                <div className="text">
                    <div className="text">
                        <h2>1. General purpose</h2>
                        <p>
                            These General Terms and Conditions (hereinafter referred to as “GTC”) are part
                            of every agreement concerning the User and the arrangement of transportation
                            services provided by Blackhansa GmbH, Halensee str.3, 10711 Berlin
                            (hereinafter referred to as “Blackhansa”).
                        </p>
                        <p>
                            The GTC apply to contracts for services rendered, in particular in the area of
                            personnel transport.
                        </p>
                        <p>
                            Deviations from the user terms, even in the case of letters of confirmation and
                            unconditional services, are hereby rejected. Anything outside of these terms
                            shall only apply if it has been expressly agreed to in writing by the management
                            of Blackhansa.
                        </p>
                        <p>
                            Individual agreements with the client have priority over these terms and
                            conditions. For the content of such agreements, a written contract or our
                            written confirmation shall prevail.
                        </p>
                        <p>
                            The language of the agreement is English.
                        </p>

                        <h2>2. Conclusion of contract</h2>
                        <p>
                            Our offers are non-binding.
                        </p>
                        <p>
                            The client may submit his contract offer in writing or verbally. The User enters a
                            contract for a transportation service by transmitting the order data.
                        </p>
                        <p>
                            By accessing, registering for, or using the Blackhansa website or app the User
                            accepts the application of these GTC. Therefore, please read these GTCs
                            carefully before accessing, registering for, or using our platforms.
                        </p>
                        <p>
                            Blackhansa reserves the right to make modifications of these GTC, which will
                            be posted at this location. Blackhansa will inform the User of these changes by
                            the email provided by the user. In case the user does not object to the changes
                            in 1 month the acceptance from the user will be automatically granted.
                        </p>
                        <p>
                            Blackhansa will inform the User about the possibility to object to the
                            modification and regarding the 1 month deadline.
                        </p>

                        <h2>3. Contractual obligations of the customer</h2>
                        <p>
                            The customer is obliged to pay the agreed fee at maturity.
                        </p>

                        <h2>4. Prices and terms of payment</h2>
                        <p>
                            Unless otherwise agreed in individual cases, our current prices apply at the
                            time of the conclusion of the contract. Our current price lists can be made
                            available to the customer by email at any time upon request.
                        </p>
                        <p>
                            The client may make the payment by credit card (VISA, Mastercard), cash, POS
                            or invoice.
                        </p>
                        <p>
                            The invoice amount is due and payable in 14 days without deduction from
                            billing.
                        </p>
                        <p>
                            Upon expiry of the above payment period, the customer is in default. The
                            invoice amount is subject to interest during the default at the applicable
                            statutory default interest rate.
                        </p>
                        <h2>5. Liability</h2>
                        <p>
                            Unless otherwise stated in these GTC, including the following provisions, we
                            shall be liable in the event of a breach of contractual and non-contractual
                            obligations in accordance with the relevant statutory provisions.
                        </p>
                        <p>
                            We assume no liability for the breach of contract or the proper performance of
                            the contract by third-party service providers.
                        </p>
                        <p>
                            We are liable for damages – for whatever legal reason – in the case of intent
                            and gross negligence. Blackhansa is liable only for a breach of a fundamental
                            contractual obligation and only for foreseeable and typical damages.
                        </p>
                        <p>
                            Fundamental contractual obligations are those which, when fulfilled, enable the
                            proper execution of the contract and are obligations upon which the User can
                            regularly rely. Limitation of liability does not apply in the context of provided
                            guarantees and in the case of intentional harm to life, body, or health as well as
                            for claims under the Product Liability Act.
                        </p>

                        <h2>6. Cancellation</h2>
                        <p>
                            A booking made in accordance with these GTC is binding.
                        </p>
                        <p>
                            A cancellation can be made in writing, by e-mail or from the Blackhansa App /
                            Website. The customer has to pay the already incurred costs or expenses if the
                            cancellation is made less than an hour before the ride.
                        </p>
                        <p>
                            We only charge a cancellation fee of 100% if the cancellation is done one hour
                            before the ride. When the cancellation is done more than one hour before the
                            agreed time of the ride we are obligated to refund any payments the client
                            made.
                        </p>
                        <p>
                            If the customer does not appear at the agreed pick-up time at the agreed pick-
                            up time, without informing us about this, a cancellation fee of 100% will also
                            apply. A ride is considered a no-show if the User, or passenger, has not shown
                            up without cancellation within 30 minutes after the agreed pickup time at the
                            agreed pickup location (an exception is made for rides where the pickup place
                            is at the airport or train station, in this case the ride is considered a no-show
                            after 60 minutes). If a customer does not show up, the ride must be paid for in
                            full, whereas possible surcharges for waiting time do not apply.
                        </p>

                        <h2>7. Offsetting against counterclaims</h2>
                        <p>
                            The customer can only set off against our claims if the counterclaim of the
                            customer is undisputed or a legally valid title exists. A right of retention can
                            only be asserted if it is based on claims from the same contractual relationship.
                        </p>

                        <h2>8. Exclusion of carriage, transport of dangerous substances</h2>
                        <p>
                            The respective chauffeur is free to exclude persons from the carriage who
                            willfully or grossly negligently damage the vehicle.
                        </p>
                        <p>
                            Our vehicles may not be used for the carriage of dangerous substances.
                        </p>

                        <h2>9. Data storage</h2>
                        <p>
                            The customer agrees that all customer data necessary for the proper order
                            processing will be stored by us.
                        </p>
                        <h2>10. Liability of the Customer</h2>
                        <p>
                            The customer is responsible for all culpable of him, i.e., intentionally and
                            negligently caused damage to the vehicle and to the driver personally and
                            indefinitely.
                        </p>
                        <p>
                            In addition, the customer is liable indefinitely and personally for all damages
                            incurred during use for a prohibited purpose.
                        </p>
                        <p>
                            During the entirety of the transportation service, all customers must follow the
                            regulations which apply to the relevant Road Traffic Act, especially the seat belt
                            regulations.
                        </p>
                        <p>
                            The customer must pay for any damage caused to the interior or exterior of any
                            vehicle by said customer. Any loss of earning if the vehicle is out of service for
                            the rest of the day will also be billed to the customer.
                        </p>

                        <h2>11. Complaints Procedure</h2>
                        <p>
                            We are very proud of our high standards of customer service. However, in the
                            event that we fail in meeting these standards, please do not hesitate to write to
                            us at the address: <a href="mailto:office@blackhansa.com">office@blackhansa.com</a>
                        </p>

                        <h2>12. Jurisdiction</h2>
                        <p>
                            The law of the Federal Republic of Germany, which is applicable to domestic
                            business, is effective for all legal relationships between Blackhansa and the
                            User. The place of fulfillment is Berlin. The exclusive place of jurisdiction is
                            Berlin, insofar as the User is a merchant according to the Commercial Code, or,
                            upon suing, has no fixed place of residence in Germany. Legally binding
                            jurisdictions remain unaffected.
                        </p>

                        <h2>13. Severability Clause</h2>
                        <p>
                            Should one or more provisions of these terms and conditions be or become
                            wholly or partially invalid or void, or should this agreement contain a gap, the
                            validity of the provisions of these terms and conditions shall otherwise remain
                            unaffected.
                        </p>

                        <h2>14. Company Identification</h2>
                        <p>
                            The contact details of Blackhansa are as follows:
                        </p>
                        <p>
                            blackhansa GmbH<br />
                            10711 Halensee str. 3<br />
                            Email: <a href="mailto:office@blackhansa.com">office@blackhansa.com</a><br />
                            Telefon: 01734190563<br />
                            Website: <a href="https://www.blackhansa.de" target="_blank" rel="noopener noreferrer">www.blackhansa.de</a><br />
                            USt.-IdNr.: DE320613673<br />
                            President: Thomas Miu, CEO: Waldemar Fink<br />
                            Amtsgericht: Berlin<br />
                            Handelsregister: HRB 201339B<br />
                            Bank: Sparkasse BLZ: 10050000 Kontonr.: 0190780401 <br />
                            Kontoinhaber: blackhansa GmbH BIC: BELADEBEXXX IBAN: DE 0710 0500 0001 9078 0401
                        </p>

                    </div>

                </div>
            </div>
        </div>
    )

}

export default TermsAndConditions
