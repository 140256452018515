import { TimePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { useState, useEffect } from "react"

interface StyledTmimePickerProps {
  label?: string
  labelColor?: string
  backgroundColor?: string
  readOnly?: boolean
  selectedTime: dayjs.Dayjs | null
  setSelectedTime: (time: dayjs.Dayjs | null) => void
}

const StyledTimePicker = (props: StyledTmimePickerProps) => {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const handleTimeChange = (time: dayjs.Dayjs | null) => {
    props.setSelectedTime(time);
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const threshold = 991;

      if (screenWidth < threshold) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="styled-time-picker">
      {
        props.label ? <div className="label" style={{ color: props.labelColor + "" }}>{props.label}</div> : <></>
      }
      <TimePicker sx={{
        width: '100%',
        backgroundColor: props.backgroundColor,
        borderRadius: '1rem',
        "& input": {
          color: '#696969',
          padding: '1rem 1rem',
          height: '1rem',
          cursor: 'pointer',
          fontSize: 12
        },
        "& fieldset": { border: 'none' }
      }}
        ampm={false}
        value={props.selectedTime}
        readOnly={props.readOnly}
        orientation={isMobile ? 'portrait' : 'landscape'}
        onChange={handleTimeChange}
      />
    </div>
  )
}

export default StyledTimePicker