import { useEffect, useState } from "react"
import { PopupType } from "../sdk/utils/types"

interface PopupProps extends PopupType {}

const Popup = (props: PopupProps) => {
    const [ show, setShow] = useState<boolean>(false)
    const [ shouldRemove, setShouldRemove ] = useState<boolean>(false);

    useEffect(() => {
        setShow(true);
        const timer = setTimeout(() => {
          setShow(false);
          const removeTimer = setTimeout(() => {
            setShouldRemove(true);
          }, 500);
          return () => clearTimeout(removeTimer);
        }, 2000);
    
        return () => clearTimeout(timer);
      }, []);

    function backgroundColor(): string {
        switch (props.type) {
            case 'OK':
                return '#579485'
            case 'ERROR':
                return '#d64734'
            case 'INFO':
                return '#cacb4c'
            default:
                return '#ffffff'
        }
    }

    if (shouldRemove) {
        return null; // Return null to remove the component from the DOM
    }

    return (
        <div style={{
            backgroundColor: backgroundColor(),
            color: 'white',
            transform: show ? 'translateY(0)' : ''
        }} className="popup">
            <div className="text">{props.message}</div>
        </div>
    )
}

export default Popup