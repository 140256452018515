import { NavLink } from "react-router-dom"
import { BLACKHANSA, FACEBOOK_ICON, INSTAGRAM_ICON, LINKEDIN_ICON, YOUTUBE_ICON } from "../assets/exports"
import { URLS } from "../sdk/utils/consts"


const Footer = () => {
    return (
        <div className="footer">
            <div className="left">
                <div className="text">© <span className="copyright">2023 blackhansa GmbH</span></div>
                <div className="text">
                    <NavLink to={URLS.TermsAndConditions} className="link">
                        Terms and Conditions
                    </NavLink>
                </div>
                <div className="text link">
                <NavLink to={URLS.PrivacyPolicy} className="link">
                        Privacy Policy
                    </NavLink>
                </div>
                <div className="text">
                    <NavLink to={URLS.CookiePolicy} className="link">
                        Cookie Policy
                    </NavLink>
                </div>
                <div className="text">
                    <NavLink to={URLS.LegalNotice} className="link">
                        Legal Notice
                    </NavLink>
                </div>
            </div>
            <div className="right">
                <div className="icon-row">
                    <a href="https://www.linkedin.com/company/blackhansa" target="_blank" rel="noopener noreferrer" className="link">
                        <img src={LINKEDIN_ICON} alt="LinkedIn" className="icon" />
                    </a>
                    <a href="https://www.facebook.com/blackhansaHQ/" target="_blank" rel="noopener noreferrer" className="link">
                        <img src={FACEBOOK_ICON} alt="Facebook" className="icon" />
                    </a>
                    {/* <img src={INSTAGRAM_ICON} alt="" className="icon" /> */}
                    <a href="https://www.youtube.com/channel/UCCcba_Cb6aVrhZ7WKvyq0dQ" target="_blank" rel="noopener noreferrer" className="link">
                        <img src={YOUTUBE_ICON} alt="YouTube" className="icon" />
                    </a>

                </div>
                <img className="brandname" src={BLACKHANSA} alt="" />
            </div>
        </div>
    )
}

// Social Media Links
// https://www.facebook.com/blackhansaHQ
// https://www.youtube.com/@blackhansa65
// https://www.linkedin.com/company/blackhansa/

export default Footer