import axios from 'axios';
import { HTTPMethod } from './requests';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

export interface Request<T> {
  path: string;
  method: HTTPMethod;
  queryParameters?: { [key: string]: string };
  bodyParameters?: { [key: string]: any };
}

export class SumUpRequest<T> implements Request<T> {
  path: string;
  method: HTTPMethod;
  queryParameters?: { [key: string]: string };
  bodyParameters?: { [key: string]: any };

    constructor(
        path: string,
        method: HTTPMethod,
        queryParameters?: { [key: string]: any },
        bodyParameters?: { [key: string]: any },
    ) {
        this.path = path;
        this.method = method;
        this.queryParameters = queryParameters;
        this.bodyParameters = bodyParameters;
    }

    async execute(): Promise<T> {
      
        const { data: response } = await axios({
            method: this.method,
            url: `${process.env.REACT_APP_SUM_UP_API_URL}${this.path}`,
            params: this.queryParameters,
            data: this.bodyParameters,
            headers: { 
                'Accept': 'application/json',
                'Authorization': 'Bearer '+process.env.REACT_APP_SUM_UP_SECRET_KEY
                // 'Authorization': 'Bearer sup_sk_foXRn3IMOfdxnQo4iq1R08ldpLeM7FIEP'
            },
            withCredentials: false
        });
      console.log('SUMUP SDK ausgefuehrt')

      return Promise.resolve(response as T)
    }
}