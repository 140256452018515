import { Fragment, useContext, useEffect, useRef, useState } from "react"
import dayjs from "dayjs"
import { DatePicker, TimePicker } from "@mui/x-date-pickers"
import { CHEVRON_UP, STAR, TIMES } from "../assets/exports"
import TripModel from "../sdk/api/models/trip"
import { PopupContext } from "./PopupContext"
import { API as ReviewAPI } from '../sdk/api/endpoints/review'
import { UserContext } from "./UserContext"
import { API as BookingAPI } from '../sdk/api/endpoints/booking/booking'


interface RideInterface {
    ride: TripModel
    isHistory: boolean
}

const Ride = (props: RideInterface) => {
    const { addPopup } = useContext(PopupContext)
    const { user } = useContext(UserContext)

    const [open, setOpen] = useState<boolean>(false)
    const [edit, setEdit] = useState<boolean>(false)

    const [bookingNumber, setBookingNumber] = useState<string>(props.ride.number ? props.ride.number : '')
    const [rideClass, setRideClass] = useState<string>(props.ride.categories.name ? props.ride.categories.name : '')
    const [date, setDate] = useState<dayjs.Dayjs | null>(dayjs(`${props.ride.date}T${props.ride.pickup_hour.toString().padStart(2, '0')}:${props.ride.pickup_min.toString().padStart(2, '0')}`))
    const [distance, setDistance] = useState<string>(props.ride.distance ? props.ride.distance + ' km' : '')
    const [hours, setHours] = useState<string>(props.ride.hours ? props.ride.hours + ' h' : '')
    const [details, setDetails] = useState<string>(props.ride.special_request ? props.ride.special_request : '')
    const [pickupAdress, setPickupAdress] = useState<string>(props.ride.pickup_address ? props.ride.pickup_address : '')
    const [dropoffAdress, setDropoffAdress] = useState<string>(props.ride.drop_address ? props.ride.drop_address : '')
    const [guest, setGuest] = useState<string>(props.ride.name ? props.ride.name : '')
    const [pickupSign, setPickupSign] = useState<string>(props.ride.pickup_sign ? props.ride.pickup_sign : '')

    const [openReview, setOpenReview] = useState<boolean>(false)
    const [reviewText, setReviewText] = useState<string>('')
    const [currentNumberOfStars, setCurrentNumberOfStars] = useState<number>(0)
    const [newNumberOfStars, setNewNumberOfStars] = useState<number>(0)
    const reviewRef = useRef<HTMLDivElement>(null)

    const [openConfirmCancel, setOpenConfirmCancel] = useState<boolean>(false)
    const cancelRef = useRef<HTMLDivElement>(null)
    const [openConfirmSave, setOpenConfirmSave] = useState<boolean>(false)
    const saveRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            if (openReview && reviewRef.current && !reviewRef.current.contains(event.target as any)) {
                setOpenReview(false)
            }
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [reviewRef, openReview])

    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            if (openConfirmCancel && cancelRef.current && !cancelRef.current.contains(event.target as any)) {
                setOpenConfirmCancel(false)
            }
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [cancelRef, openConfirmCancel])

    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            if (openConfirmSave && saveRef.current && !saveRef.current.contains(event.target as any)) {
                setOpenConfirmSave(false)
            }
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [saveRef, openConfirmSave])

    function submitReview() {
        if (reviewValid()) {
            ReviewAPI.submitReview({
                booking_id: props.ride.id,
                user_id: user.id,
                rating: currentNumberOfStars.toString(),
                comment: reviewText
            }).then(() => {
                setOpenReview(false)
                setReviewText('')
                setCurrentNumberOfStars(0)
                addPopup({
                    message: 'Thank you for submitting a review!',
                    type: 'OK'
                })
            }).catch((err) => {
                addPopup({
                    message: 'Something went wrong, please try again later',
                    type: 'ERROR'
                })
            })
        }
        else {
            addPopup({
                message: 'Please fill out the form',
                type: 'ERROR'
            })
        }
    }

    function reviewValid(): boolean {
        return currentNumberOfStars > 0 && reviewText.trim() !== ''
    }

    function cancelBooking() {
        // BookingAPI.cancelBooking(props.ride.id).then((response) => {
        //     addPopup({
        //         message: 'Your booking was canceled.',
        //         type: 'OK'
        //     })
        //     setOpenConfirmCancel(false)
        // }).catch(() => {
        //     addPopup({
        //         message: 'Something went wrong. Please try again later',
        //         type: 'ERROR'
        //     })
        // })

    }

    function saveBooking() {
        // BookingAPI.saveBookingChanges(props.ride.id).then((response) => {
        //     addPopup({
        //         message: 'Your booking was updated.',
        //         type: 'OK'
        //     })
        //     setOpenConfirmSave(false)
        // }).catch(() => {
        //     addPopup({
        //         message: 'Something went wrong. Please try again later',
        //         type: 'ERROR'
        //     })
        // })
        setOpenConfirmSave(false)

    }

    return (
        <div className="ride">
            {
                openReview ?
                    <div className="fullscreen-container">
                        <div ref={reviewRef} className="review-box">
                            <div className="review-header">
                                <div className="text">WRITE A REVIEW FOR BOOKING NO. {bookingNumber}</div>
                                <img onClick={() => setOpenReview(false)} src={TIMES} alt="" className="close-button" />
                            </div>
                            <div className="review-body">
                                <div className="info-cell">
                                    <div className="cell-header">Vehicle Info</div>
                                    <div className="text">{rideClass}</div>
                                </div>
                                <div className="info-cell">
                                    <div className="cell-header">Chauffeur</div>
                                    <div className="text">{props.ride.driver ? props.ride.driver : 'BH Driver'}</div>
                                </div>
                                <div className="review-span2-container">
                                    <div className="label">Addidtional Info</div>
                                    <textarea spellCheck={false} value={reviewText} onChange={(evt) => setReviewText(evt.target.value)}></textarea>
                                </div>
                                <div className="review-span2-container">
                                    <div className="label">Ratings</div>
                                    <div className="star-container">
                                        {
                                            [...Array(5)].map((_, index) => {
                                                return (
                                                    <img onMouseLeave={() => setNewNumberOfStars(0)} onMouseEnter={() => setNewNumberOfStars(index + 1)} onClick={() => {
                                                        if (currentNumberOfStars === index + 1) {
                                                            setCurrentNumberOfStars(0)
                                                            setNewNumberOfStars(0)
                                                        }
                                                        else {
                                                            setCurrentNumberOfStars(index + 1)
                                                        }
                                                    }} style={{ filter: index + 1 <= currentNumberOfStars || index + 1 <= newNumberOfStars ? 'invert(77%) sepia(20%) saturate(1522%) hue-rotate(340deg) brightness(98%) contrast(100%)' : '' }} src={STAR} alt="" className="star" />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="review-footer">
                                <div onClick={() => setOpenReview(false)} className="button">Close</div>
                                <div onClick={() => submitReview()} className="button submit">Submit</div>
                            </div>
                        </div>
                    </div> : <></>
            }
            {
                openConfirmCancel ?
                    <div className="fullscreen-container">
                        <div ref={cancelRef} className="cancel-box">
                            <div className="review-header">
                                <div className="text">Are you sure you want to cancel booking #{bookingNumber}?</div>
                                <img onClick={() => setOpenConfirmCancel(false)} src={TIMES} alt="" className="close-button" />
                            </div>
                            <div className="review-body">
                                {/* <div onClick={() => cancelBooking()} className="button confirm">Yes</div>
                                <div className="button confirm"
                                    onClick={() => window.open('mailto:email@example.com?subject=Subject&body=Body%20goes%20here')
                                    }
                                >Yes</div> */}
                                <a
                                    style={{ textDecoration: "none" }}
                                    href={`mailto:pro@blackhansa.com?subject=Cancellation%20of%20Booking%20#${bookingNumber}&body=Please%20cancel%20booking%20#${bookingNumber}%0A%0ABest, ${guest}`}
                                >
                                    <div onClick={() => setOpenConfirmCancel(false)} className="button">
                                        Yes
                                    </div>
                                </a>

                                <div onClick={() => setOpenConfirmCancel(false)} className="button">No</div>
                            </div>
                        </div>
                    </div> : <></>
            }
            {
                openConfirmSave ?
                    <div className="fullscreen-container">
                        <div ref={cancelRef} className="cancel-box">
                            <div className="review-header">
                                <div className="text">
                                    Are you sure you want to update booking #{bookingNumber}?
                                </div>
                                <img onClick={() => setOpenConfirmSave(false)} src={TIMES} alt="" className="close-button" />
                            </div>
                            <div className="review-body">
                                <a
                                    style={{ textDecoration: "none" }}
                                    href={`mailto:pro@blackhansa.com?subject=Update%20of%20Booking%20#${bookingNumber}&body=Please%20update%20booking%20#${bookingNumber}%0A%0ABest, ${guest}`}
                                >
                                    <div className="button"
                                        onClick={() => setOpenConfirmSave(false)}
                                    >
                                        Yes
                                    </div>
                                </a>

                                <div className="button"
                                    onClick={() => setOpenConfirmSave(false)}
                                >
                                    No
                                </div>
                            </div>
                        </div>
                    </div> : <></>
            }
            {
                !open ?
                    <div className="content-container">
                        {/* not expanded mode */}
                        <div className="cell">
                            <div className="label">Booking number</div>
                            <input type="number" readOnly={true} className="text" value={bookingNumber} onChange={(evt) => setBookingNumber(evt.target.value)} />
                        </div>
                        <div className="cell">
                            <div className="label">Class</div>
                            <input type="text" readOnly={true} className="text" value={rideClass} onChange={(evt) => setRideClass(evt.target.value)} />
                        </div>
                        <div className="cell">
                            <div className="label">Date</div>
                            <DatePicker
                                value={date}
                                sx={{
                                    width: '100%',
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: '1rem',
                                    border: '1px solid #555555',
                                    "& input": {
                                        color: '#696969',
                                        padding: '1rem 1rem',
                                        height: '1rem',
                                        cursor: 'pointer'
                                    },
                                    "& fieldset": { border: 'none' }
                                }}
                                onChange={(newDate) => setDate(newDate)}
                                format={'ddd, MMM DD, YYYY'}
                                disabled={!edit}
                            />
                        </div>
                        <div className="cell">
                            <div className="label">Time</div>
                            <TimePicker
                                sx={{
                                    width: '100%',
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: '1rem',
                                    border: '1px solid #555555',
                                    "& input": {
                                        color: '#696969',
                                        padding: '1rem 1rem',
                                        height: '1rem',
                                        cursor: 'pointer'
                                    },
                                    "& fieldset": { border: 'none' }
                                }}
                                ampm={false}
                                value={date}
                                disabled={!edit}
                            />
                        </div>
                        <div className="tablet-fillerspace"></div>
                        {
                            !props.isHistory ?
                                <div className="button-container">
                                    <div className="button"
                                        // onClick={() => setEdit(!edit)} 
                                        // onClick={() => {
                                        //     if (edit == false)
                                        //         setEdit(!edit)
                                        //     else
                                        //         setOpenConfirmSave(true)
                                        // }}
                                        onClick={() => {
                                            setOpenConfirmSave(true)
                                        }}
                                    >
                                        {/* {edit ? 'Save' : 'Edit'} */}
                                        Edit
                                    </div>
                                    <div onClick={() => setOpen(!open)} className="icon-container">
                                        <img src={CHEVRON_UP} alt="" className="icon" />
                                    </div>
                                    <div onClick={() => setOpenConfirmCancel(true)} className="button">Cancel booking</div>
                                </div> :
                                <Fragment>
                                    <div className="cell">
                                        <div className="label">Pickup Address</div>
                                        <input type="text" readOnly={!edit} className="text" value={pickupAdress} onChange={(evt) => setPickupAdress(evt.target.value)} />
                                    </div>
                                    <div onClick={() => setOpen(!open)} className="history-details-button">
                                        Details
                                    </div>
                                </Fragment>
                        }
                    </div> :
                    <div className="content-container">
                        {/* expanded mode */}
                        <div className="cell">
                            <div className="label">Booking number</div>
                            <input type="number" readOnly={true} className="text" value={bookingNumber} onChange={(evt) => setBookingNumber(evt.target.value)} />
                        </div>
                        <div className="cell">
                            <div className="label">Class</div>
                            <input type="text" readOnly={true} className="text" value={rideClass} onChange={(evt) => setRideClass(evt.target.value)} />
                        </div>
                        <div className="cell">
                            <div className="label">Date</div>
                            <DatePicker
                                value={date}
                                sx={{
                                    width: '100%',
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: '1rem',
                                    border: '1px solid #555555',
                                    "& input": {
                                        color: '#696969',
                                        padding: '1rem 1rem',
                                        height: '1rem',
                                        cursor: 'pointer'
                                    },
                                    "& fieldset": { border: 'none' }
                                }}
                                onChange={(newDate) => setDate(newDate)}
                                format={'ddd, MMM DD, YYYY'}
                                disabled={!edit}
                            />
                        </div>
                        <div className="cell">
                            <div className="label">Time</div>
                            <TimePicker
                                sx={{
                                    width: '100%',
                                    backgroundColor: '#f5f5f5',
                                    borderRadius: '1rem',
                                    border: '1px solid #555555',
                                    "& input": {
                                        color: '#696969',
                                        padding: '1rem 1rem',
                                        height: '1rem',
                                        cursor: 'pointer'
                                    },
                                    "& fieldset": { border: 'none' }
                                }}
                                ampm={false}
                                value={date}
                                disabled={!edit}
                            />
                        </div>
                        {
                            props.ride.type === 'Transfer' ?
                                <div className="cell">
                                    <div className="label">Distance</div>
                                    <input type="text" readOnly={true} className="text" value={distance} onChange={(evt) => setDistance(evt.target.value)} />
                                </div> :
                                <div className="cell">
                                    <div className="label">Hours</div>
                                    <input type="text" readOnly={!edit} className="text" value={hours} onChange={(evt) => setHours(evt.target.value)} />
                                </div>
                        }
                        <div className="cell">
                            <div className="label">Details</div>
                            <input type="text" readOnly={!edit} className="text" value={details} onChange={(evt) => setDetails(evt.target.value)} />
                        </div>
                        <div className="cell big">
                            <div className="label">Pickup Address</div>
                            <input type="text" readOnly={!edit} className="text" value={pickupAdress} onChange={(evt) => setPickupAdress(evt.target.value)} />
                        </div>
                        {
                            props.ride.type === 'Transfer' ?
                                <div className="cell big">
                                    <div className="label">Dropoff Address</div>
                                    <input type="text" readOnly={!edit} className="text" value={dropoffAdress} onChange={(evt) => setDropoffAdress(evt.target.value)} />
                                </div> : <></>
                        }
                        <div className="cell">
                            <div className="label">Guest</div>
                            <input type="text" readOnly={true} className="text" value={guest} onChange={(evt) => setGuest(evt.target.value)} />
                        </div>
                        <div className="cell">
                            <div className="label">Pickup sign</div>
                            <input type="text" readOnly={!edit} className="text" value={pickupSign} onChange={(evt) => setPickupSign(evt.target.value)} />
                        </div>
                        {
                            props.ride.type === 'Hourly' ?
                                <Fragment>
                                    <div className="fillerspace"></div>
                                    <div className="fillerspace"></div>
                                </Fragment> : <></>
                        }
                        <div className="fillerspace"></div>
                        <div className="fillerspace noTablet"></div>
                        <div style={{ justifyContent: props.isHistory ? 'center' : '' }} className="button-container">
                            {
                                !props.isHistory ?
                                    <div className="button"
                                        // onClick={() => setEdit(!edit)}
                                        // onClick={() => {
                                        //     if (edit == false)
                                        //         setEdit(!edit)
                                        //     else
                                        //         setOpenConfirmSave(true)
                                        // }}
                                        onClick={() => { setOpenConfirmSave(true) }}
                                    >
                                        {/* {edit ? 'Save' : 'Edit'} */}
                                        Edit
                                    </div> : <></>
                            }
                            <div onClick={() => setOpen(!open)} className="icon-container">
                                <img style={{ transform: 'rotate(0deg)' }} src={CHEVRON_UP} alt="" className="icon" />
                            </div>
                            {
                                !props.isHistory ?
                                    <div
                                        onClick={() => setOpenConfirmCancel(true)}
                                        className="button">Cancel booking
                                    </div> : <></>
                            }
                        </div>
                        <div className="fillerspace"></div>
                        <div className="fillerspace"></div>
                        <div className="fillerspace"></div>
                        <div className="text-container">
                            Edit & Cancelation policy<br /><br />
                            Transfer bookings - canceled free of charge up to one hour before the pick up time. Hourly bookings - canceled free of charge up to 12 hours before the pick up time.
                        </div>
                        <div className="fillerspace noTablet"></div>
                        {/* {
                            props.isHistory ?
                                <div
                                    onClick={() => (props.ride.status !== 'Cancelled' &&
                                        props.ride.status !== 'Cancelled Paid' &&
                                        !props.isHistory) ? setOpenConfirmCancel(true) : {}} className={props.ride.status !== 'Cancelled' &&
                                            props.ride.status !== 'Cancelled Paid' &&
                                            !props.isHistory ? "history-details-button" : "history-details-button-disabled"}>
                                    Cancel
                                </div> : <></>
                        } */}
                        {
                            props.ride.status === 'Finished' ?
                                <div onClick={() => setOpenReview(true)} className="history-details-button">
                                    Review
                                </div> : <></>
                        }
                    </div>
            }
        </div>
    )
}

export default Ride