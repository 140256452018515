import { Fragment, useContext, useState } from "react"
import { PopupContext } from "./PopupContext"
import Popup from "./Popup"

const PopupBounds = () => {
    const { popups } = useContext(PopupContext)

    return (
        <div className="popup-bounds">
            {
                popups.map((popup, key) => {
                    return (
                        <Fragment key={key}>
                            <Popup message={popup.message} type={popup.type} />
                        </Fragment>
                    )
                })
            }
        </div>
    )
}

export default PopupBounds