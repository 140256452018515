import { Fragment, useContext, useEffect, useState } from "react"
import StyledTextInput from "../components/StyledTextInput"
import StyledCheckbox from "../components/StyledCheckbox"
import { API as LoginAPI } from '../sdk/api/endpoints/login'
import validator from "validator"
import { PopupContext } from "../components/PopupContext"
import Loading from "../components/Loading"
import { useNavigate } from 'react-router-dom';


const SignUp = () => {
    const { addPopup } = useContext(PopupContext)
    const [checkbox_1, setCheckbox_1] = useState<boolean>(false)
    const [checkbox_2, setCheckbox_2] = useState<boolean>(false)
    const [loadingRegister, setLoadingRegister] = useState<boolean>(false)

    const [fullName, setFullName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const navigate = useNavigate();

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                register();
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [fullName, email, password]);

    function register() {
        if (validate()) {
            setLoadingRegister(true)
            LoginAPI.register(fullName.trim(), email.trim(), password.trim()).then((res) => {
                addPopup({
                    message: 'Registration complete!',
                    type: 'OK'
                })
                console.log(res)
                setFullName('')
                setEmail('')
                navigate('/sign-in')
            }).catch(() => {
                addPopup({
                    message: 'Fehler beim Registrieren',
                    type: 'ERROR'
                })
            }).finally(() => {
                setPassword('')
                setConfirmPassword('')
                setCheckbox_1(false)
                setCheckbox_2(false)
                setLoadingRegister(false)
            })
        }
        else {
            addPopup({
                message: 'Please fill out the form',
                type: 'ERROR'
            })
        }
    }

    function validate(): boolean {
        return fullName.trim() !== '' &&
            validator.isEmail(email.trim()) &&
            password.trim() !== '' &&
            password === confirmPassword &&
            checkbox_1 &&
            checkbox_2
    }

    return (
        <div className="sign-up">
            <div className="page-header">Profile</div>
            <div className="header">Sign Up</div>
            <div className="text">All fields marked with the symbol <span style={{ color: 'red' }}>*</span> are mandatory.</div>
            <div className="form-container">
                <StyledTextInput value={fullName} onChange={setFullName} style={{ backgroundColor: "#f5f5f5" }} labelColor={"#121212"} placeholder="Full name">Full name <span style={{ color: 'red' }}>*</span></StyledTextInput>
                <StyledTextInput value={email} onChange={setEmail} style={{ backgroundColor: "#f5f5f5" }} labelColor={"#121212"} placeholder="Email">Email <span style={{ color: 'red' }}>*</span></StyledTextInput>
                <StyledTextInput value={password} onChange={setPassword} type="password" style={{ backgroundColor: "#f5f5f5" }} labelColor={"#121212"} placeholder="Password">Password <span style={{ color: 'red' }}>*</span></StyledTextInput>
                <StyledTextInput value={confirmPassword} onChange={setConfirmPassword} type="password" style={{ backgroundColor: "#f5f5f5" }} labelColor={"#121212"} placeholder="Confirm password">Confirm password <span style={{ color: 'red' }}>*</span></StyledTextInput>
                <StyledCheckbox active={checkbox_1} setActive={setCheckbox_1}>
                    <div className="checkbox-text">I confirm having read and fully understood the Privacy Statement.</div>
                </StyledCheckbox>
                <StyledCheckbox active={checkbox_2} setActive={setCheckbox_2}>
                    <div className="checkbox-text">I confirm my acceptance to the processing of my personal information for marketing and informative and promotional purposes, included of commercial nature, among which your newsletter, exclusively by Blackhansa.</div>
                </StyledCheckbox>
                <div
                    onClick={() => !loadingRegister ? register() : {}} className="button"
                >
                    {
                        loadingRegister ?
                            <div className="loading-container">
                                <Loading />
                            </div> :
                            <Fragment>
                                Proceed
                            </Fragment>
                    }
                </div>
            </div>
        </div>
    )
}

export default SignUp