import { HTTPMethod } from "../../requests";
import { SumUpRequest } from "../../sumup";


export class API {
    public static createCheckout(checkout: Checkout): Promise<any> {
        return new SumUpRequest<any>('/v0.1/checkouts/', HTTPMethod.POST, undefined, checkout).execute()
    }

    public static processCheckout(checkout_id: string, card: Card): Promise<any> {
        return new SumUpRequest<any>(`/v0.1/checkouts/${checkout_id}`, HTTPMethod.PUT, undefined, card).execute()
    }
}

export interface Card {
    card: {
        cvv: string
        expiry_month: string
        expiry_year: string
        name: string
        number: string
    }
    "payment_type": "card"
}

export interface Checkout {
    amount: number
    checkout_reference: string
    currency: string
    merchant_code: string
    // merchant_code: string | undefined

    customer_id?: string
    personal_details?: {
        address?: {
            city?: string
            country?: string
            line_1?: string
            postal_code?: string
        }
        email?: string
        first_name?: string
        last_name?: string
        tax_id?: string
    }
    description?: string
    payment_type?: string
}