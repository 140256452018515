import { useContext, useEffect } from "react";
import React from "react";
import { SidebarContext } from "./SidebarContext";
import { NavLink } from "react-router-dom";
import { URLS } from "../sdk/utils/consts";
import { BLACKHANSA, TIMES } from "../assets/exports";
import { UserContext } from "./UserContext";


interface Sidebarprobs {
    onCloseClick: () => void
}

const Sidebar = (props: Sidebarprobs) => {
    const sidebarContext = useContext(SidebarContext);
    const { open, setOpen } = useContext(SidebarContext);
    const sidebarRef = React.useRef<HTMLDivElement>(null)
    const { user } = useContext(UserContext)

    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            if (sidebarContext.open && sidebarRef.current && !sidebarRef.current.contains(event.target as any)) {
                props.onCloseClick();
            }
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [sidebarRef, props, sidebarContext.open])

    return (
        <>
            <div ref={sidebarRef} className={`sidebar-container ${sidebarContext.open ? 'sidebar-open' : 'closed'}`}>
                <div className="close-button-container">
                    <img src={BLACKHANSA} alt="" className="logo" />
                    <img src={TIMES} onClick={() => props.onCloseClick()} className="close-button" alt="" />
                </div>
                <div className="link-container">
                    <NavLink onClick={() => setOpen(false)} to={URLS.Home} className="link">Home</NavLink>
                    <NavLink onClick={() => setOpen(false)} to={URLS.AboutUs} className="link hideMobile">About us</NavLink>
                    <NavLink onClick={() => setOpen(false)} to={URLS.ServiceClass} className="link hideMobile">Service Class</NavLink>
                    <NavLink onClick={() => setOpen(false)} to={URLS.Contact} className="link hideMobile">Contact</NavLink>
                    <NavLink onClick={() => setOpen(false)} to={URLS.Career} className="link hideMobile">Career</NavLink>
                    <NavLink onClick={() => setOpen(false)} to={URLS.Profile} className="link hideMobile">{user ? "Profile" : "Sign in"}</NavLink>
                </div>
            </div>
        </>
    );
}

export default Sidebar;