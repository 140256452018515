const LegalNotice = () => {
    // window.scrollTo(0, 0)
    return (
        <div className="legal-notice">
            <div className="page-header">Legal Notice</div>
            <div className="content">
                <div className="text">
                    <h2>1. Site Notice</h2>
                    <p>
                        Information provided according to Sec. 5 German Telemedia Act (TMG):
                        <br />
                        Waldemar Fink , Thomas Miu
                        <br />
                        blackhansa GmbH
                        <br />
                        Halensee str. 3
                        <br />
                        10711 Berlin
                    </p>

                    <h2>2. Contact:</h2>
                    <p>
                        Telephone: +49 (0) 173 4190563
                        <br />
                        Email: office@blackhansa.com
                    </p>

                    <h2>3. VAT:</h2>
                    <p>
                        VAT Id number according to Sec. 27 a German Value Added Tax Act:
                        <br />
                        DE320613673
                    </p>

                    <h2>4. Supervising authority:</h2>
                    <p>
                        Landesamt für Bürger- und Ordnungsangelegenheiten
                        <br />
                        Puttkamerstraße 16-18
                        <br />
                        10969 Berlin
                        <br />
                        <a href="http://www.berlin.de/labo/mobilitaet/fahrerlaubnisse-personen-und-gueterbefoerderung/" target="_blank" rel="noopener noreferrer">http://www.berlin.de/labo/mobilitaet/fahrerlaubnisse-personen-und-gueterbefoerderung/</a>
                    </p>

                    <h2>5. Responsible for contents acc. to Sec. 55, para. 2 German Federal Broadcasting Agreement (RstV):</h2>
                    <p>
                        blackhansa GmbH
                        <br />
                        Halensee str. 3
                        <br />
                        10711 Berlin
                    </p>

                    <h2>6. Dispute resolution</h2>
                    <p>
                        The European Commission provides a platform for online dispute resolution (OS): <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>.
                        <br />
                        Please find our email in the impressum/legal notice.
                        <br />
                        We do not take part in online dispute resolutions at consumer arbitration boards.
                    </p>

                    <h2>7. Liability for Contents</h2>
                    <p>
                        As service providers, we are liable for own contents of these websites
                        according to Sec. 7, paragraph 1 German Telemedia Act (TMG). However,
                        according to Sec. 8 to 10 German Telemedia Act (TMG), service providers are
                        not obligated to permanently monitor submitted or stored information or to
                        search for evidence that indicates illegal activities.
                    </p>
                    <p>
                        Legal obligations to removing information or blocking the use of information
                        remain unchallenged. In this case, liability is only possible at the time of
                        knowledge about a specific violation of the law. Illegal contents will be removed immediately at the time we get knowledge of them.
                    </p>

                    <h2>8. Liability for Links</h2>
                    <p>
                        Our offer includes links to external third-party websites. We have no influence
                        on the contents of those websites; therefore, we cannot guarantee those
                        contents. Providers or administrators of linked websites are always responsible
                        for their own content.
                    </p>
                    <p>
                        The linked websites had been checked for possible violations of the law at the time
                        of the establishment of the link. Illegal contents were not detected at the time
                        of the linking. A permanent monitoring of the contents of linked websites
                        cannot be imposed without reasonable indications that there has been a
                        violation of the law. Illegal links will be removed immediately at the time we get
                        knowledge of them.
                    </p>

                    <h2>9. Copyright</h2>
                    <p>
                        Contents and compilations published on these websites by the providers are
                        subject to German copyright laws. Reproduction, editing, distribution, as well as
                        the use of any kind outside the scope of the copyright law require the written
                        permission of the author or originator. Downloads and copies of these websites
                        are permitted for private use only.
                    </p>
                    <p>
                        The commercial use of our contents without permission of the originator is
                        prohibited.
                    </p>
                    <p>
                        Copyright laws of third parties are respected as long as the contents on these
                        websites do not originate from the provider. Contributions of third parties on
                        this site are indicated as such. However, if you notice any violations of
                        copyright law, please inform us. Such contents will be removed immediately.
                    </p>
                </div>
            </div>
        </div>
    )

}

export default LegalNotice
