import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { ReactNode } from "react"


interface StyledSelectProps {
    options: Array<string>
    children?: ReactNode
    labelColor?: string
    placeholder: string
    backgroundColor?: string
    inputColor?: string
    readOnly?: boolean
    value: string
    setValue: (val: string) => void
}


const StyledSelect = (props: StyledSelectProps) => {

    const handleChange = (event: SelectChangeEvent<string>) => {
        props.setValue(event.target.value)
    }

    return (
        <div className="styled-select">
            {
                props.children ? <div className="label" style={{ color: props.labelColor+"" }}>{props.children}</div> : <></>
            }
            <Select
                sx={{
                width: '100%',
                backgroundColor: props.backgroundColor,
                borderRadius: '1rem',
                color: props.inputColor ? props.inputColor : '#696969',
                "& input": { 
                    color: props.inputColor ? props.inputColor : '#696969',
                    padding: '1rem 1rem',
                    height: '1rem',
                    cursor: 'pointer'
                },
                "& fieldset": { border: 'none' }
                }}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <em>{props.placeholder}</em>;
                    }
                    else {
                        return <em style={{fontStyle: 'normal'}}>{selected}</em>;
                    }
                  }}
                value={props.value}
                onChange={handleChange}
                displayEmpty
                readOnly={props.readOnly}
                inputProps={{ 'aria-label': 'Without label' }}
            >
            {
                props.options.map((text, key) => {
                    return (
                        <MenuItem key={key} value={text}>{text}</MenuItem>
                    )
                })
            }
            </Select>
        </div>
    )
}

export default StyledSelect