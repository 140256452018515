import { NavLink, useNavigate } from "react-router-dom";
import { useEffect, useContext, useState } from "react";

// Assets
import { LOGO } from "../assets/exports"
import { URLS } from "../sdk/utils/consts";
import { SidebarContext } from "./SidebarContext";
import { UserContext } from "./UserContext";

const Header = () => {
	let navigate = useNavigate()
	const { open, setOpen } = useContext(SidebarContext)
	const [isMobile, setIsMobile] = useState<boolean>(false)
	const { user } = useContext(UserContext)

	useEffect(() => {
		const handleResize = () => {
			const screenWidth = window.innerWidth;
			const threshold = 991;

			if (screenWidth < threshold) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);


	function handleClick(url: string) {
		navigate(url)
	}

	return (
		<div className="header">
			<div className="logo-container">
				<img onClick={() => !isMobile ? handleClick(URLS.Home) : setOpen(!open)} src={LOGO} alt="" className="logo" />
			</div>
			<div className="nav-container">
				<NavLink to={URLS.Home} className="link">Home</NavLink>
				<NavLink to={URLS.AboutUs} className="link hideMobile">About us</NavLink>
				<NavLink to={URLS.ServiceClass} className="link hideMobile">Service Class</NavLink>
				<NavLink to={URLS.Contact} className="link hideMobile">Contact</NavLink>
				<NavLink to={URLS.Career} className="link hideMobile">Career</NavLink>
				{
					user ?
						<NavLink to={URLS.Profile} className="link hideMobile">Profile</NavLink>
						:
						<NavLink to={URLS.SignIn} className="link hideMobile">Sign in</NavLink>
				}
			</div>
		</div>
	)
}

export default Header