// import PanelCarrousel from "../components/Carrousels/PanelCarrousel"


const AboutUs = () => {
    return (
        <div className="about-us">
            <div className="page-header">About</div>
            <div className="content">
                <div className="top-text">In an era shifting towards autonomous transportation and focused on robotics, we stand firm in our conviction: Ground transportation is not just about moving from one place to another - it's about changing the world, with the chauffeur as the traveler's trusted partner. We are a dynamic yet seasoned team, dedicating all our resources to uphold the fundamental values our company cherishes:</div>
                {/* <div className="panel-carrousel-section">
                    <div className="panel-carrousel-container">
                        <PanelCarrousel panelImages={panelImages} />
                    </div>
                </div> */}
                <div className="text-grid">
                    <div className="text-container">
                        <div className="title">Large Fleet Of Vehicles</div>
                        <div className="text">Our fleet is equipped and accessorized to the highest standards, and customized to provide standardized quality with no variations of the features or models.</div>
                    </div>
                    <div className="text-container">
                        <div className="title">Hygiene</div>
                        <div className="text">The cars are sanitized after each transfer, using tested and specialized products that are highly microbial neutralizers with no harmful effects on health.</div>
                    </div>
                    <div className="text-container">
                        <div className="title">Punctuality</div>
                        <div className="text">Using one of the most powerful logarithmic systems and artificial intelligence, we can anticipate heavy traffic so that the chosen route will always be the fastest to destination. The same logarithm helps us to communicate within our fleet in order to avoid unpleasant timing impacts.</div>
                    </div>
                    <div className="text-container">
                        <div className="title">Online Booking & Payment</div>
                        <div className="text">Easy and simple booking system, with the option to change or cancel your ride, choice between online and cash payment.</div>
                    </div>
                    <div className="text-container">
                        <div className="title">Safety</div>
                        <div className="text">We have developed two branches that will ensure that the travel experience of the guest is at the highest level, through an entirely safe atmosphere.</div>
                    </div>
                    <div className="text-container">
                        <div className="title">Professionalism</div>
                        <div className="text">The drivers are trained towards skill development in order to offer a chauffeur - guest experience at the highest standards.</div>
                    </div>
                </div>
                {/* <div className="video-grid">
                    <div className="video-container"></div>
                    <div className="video-container"></div>
                    <div className="video-container"></div>
                    <div className="video-container"></div>
                    <div className="video-container"></div>
                    <div className="video-container"></div>
                </div> */}
            </div>
        </div>
    )
}

export default AboutUs

const panelImages: Array<string> = ['', '', '', '', '', '', '']
