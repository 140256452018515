import { useState, useContext, useEffect } from "react"
import { CLASSIC_PANELS, ELECTRIC_PANELS, URLS } from "../sdk/utils/consts"
import { useNavigate } from "react-router-dom"
import { PopupContext } from "../components/PopupContext";


// Assets
import { APPLE_DOWNLOAD_BANNER, BANNER_CAR, BLACKHANSA, EQE, EQS, E_CLASS, GOOGLE_DOWNLOAD_BANNER, HOME_PHONE_1, HOME_PHONE_2, HOME_PHONE_3, IMAGE_CARROUSEL_EXAMPLE_1, IMAGE_CARROUSEL_EXAMPLE_2, IMAGE_CARROUSEL_EXAMPLE_3, MAYBACH, S_CLASS, V_CLASS } from "../assets/exports"

//Components
import AnimatedImageCarrousel from "../components/Carrousels/AnimatedCarrousel"
import ServiceForm from "../components/ServiceForm"
import SimpleImageCarrousel from "../components/Carrousels/SimpleImageCarrousel"
import SwitchOption from "../components/SwitchOption"
import { API as CategoryAPI } from '../sdk/api/endpoints/category'
import CategoryModel from "../sdk/api/models/category";
import Loading from "../components/Loading";


const Home = () => {
    const [activeSwitchState, setActiveSwitchState] = useState<number>(0)
    let navigate = useNavigate()
    const [loadingCategories, setLoadingCategories] = useState<boolean>(false)
    const [categories, setCategories] = useState<Array<CategoryModel>>([])
    const { addPopup } = useContext(PopupContext)
    const [classicCars, setClassicCars] = useState<Array<{ image: string; title: string; subtitle: string }>>([])
    const [electricCars, setElectricCars] = useState<Array<{ image: string; title: string; subtitle: string }>>([])

    function loadCategories() {
        setLoadingCategories(true)
        CategoryAPI.getCategories().then((response) => {
            const sortedResponse = response.slice().sort((a, b) => +a.website_order - +b.website_order); // war id
            setCategories(sortedResponse)
        }).catch((err) => {
            console.log('ERROR', err)
            addPopup({
                message: 'Error loading categories',
                type: 'ERROR'
            })
        }).finally(() => {
            setLoadingCategories(false)
        })
    }

    useEffect(() => {
        loadCategories()
    }, [])

    useEffect(() => {
        console.log(categories)
        sortCars()
    }, [categories])

    function sortCars() {
        var classicCars: Array<{ image: string; title: string; subtitle: string }> = []
        var electricCars: Array<{ image: string; title: string; subtitle: string }> = []
        for (let index = 0; index < categories.length; index++) {
            const category = categories[index];


            let img
            switch (category.model) {
                case "EQS":
                    img = EQS
                    electricCars.push({ image: img, title: category.name, subtitle: category.make + " - " + category.model })

                    break;
                case "EQE":
                    img = EQE
                    electricCars.push({ image: img, title: category.name, subtitle: category.make + " - " + category.model })

                    break;
                case "Maybach":
                    img = MAYBACH
                    classicCars.push({ image: img, title: category.name, subtitle: category.make + " - " + category.model })

                    break;
                case "S":
                    // img = S_CLASS
                    img = EQS
                    classicCars.push({ image: img, title: category.name, subtitle: category.make + " - " + category.model })

                    break;
                case "E":
                    // img = E_CLASS
                    img = EQE
                    classicCars.push({ image: img, title: category.name, subtitle: category.make + " - " + category.model })

                    break;
                case "V":
                    img = V_CLASS
                    classicCars.push({ image: img, title: category.name, subtitle: category.make + " - " + category.model })

                    break;
                default:
                    img = IMAGE_CARROUSEL_EXAMPLE_1;
                    classicCars.push({ image: img, title: category.name, subtitle: category.make + " - " + category.model })

                    break;
            }
        }
        setElectricCars(electricCars);
        setClassicCars(classicCars);
    }

    // function getElectric_Panels() {
    //     var classicCars: Array<{ image: string; title: string; subtitle: string }> = []
    //     for (let index = 0; index < categories.length; index++) {
    //         const category = categories[index];
    //         if (category.name.search("Electric")) {
    //             // classicCars.add
    //         }
    //     }
    //     return classicCars
    // }

    return (
        <div className="home">
            <div className="form-section">
                <img className="section-label" src={BLACKHANSA} alt="" />
                <div className="image-carrousel-container">
                    <SimpleImageCarrousel images={[IMAGE_CARROUSEL_EXAMPLE_1, IMAGE_CARROUSEL_EXAMPLE_2, IMAGE_CARROUSEL_EXAMPLE_3]} />
                </div>
                <div className="form-container">
                    <ServiceForm />
                </div>
            </div>
            <div className="animated-carrousel-container">
                {
                    loadingCategories ?
                        <div className="loading-container"><Loading /></div> :
                        <AnimatedImageCarrousel
                            // showClassic={activeSwitchState === 1}
                            showClassic={true}
                            classicPanels={classicCars}
                            electricPanels={electricCars}
                        />
                }
            </div>
            <div className="booking-row-section">
                <div className="booking-row">
                    {/* <div className="flip-container">
                        <SwitchOption
                            activeOption={activeSwitchState}
                            setActiveOption={setActiveSwitchState}
                            fontColorActive="white"
                            fontColor="#579485"
                            activeColor="#579485"
                            options={['Electric', 'Classic']}
                        />
                    </div> */}
                    <div onClick={() => navigate(URLS.ServiceClass)} className="booking-button">Book</div>
                </div>
            </div>
            <div className="phone-section">
                <div className="panel first">
                    <img className="panel-image" src={HOME_PHONE_1} alt="" />
                    <div className="title">Airport Transfer</div>
                    <div className="text">Enjoy a stress-free journey with our Airport Transfer service. Included is an hour of free wait time, ensuring a relaxed experience even with flight delays.</div>
                    <div onClick={() => navigate(URLS.ServiceClass)} className="booking-button">Book</div>
                </div>
                <div className="panel">
                    <img className="panel-image" src={HOME_PHONE_2} alt="" />
                    <div className="title">Luxury Ride</div>
                    <div className="text">Navigate multiple destinations seamlessly with our Luxury Ride service. Let our premium limousines elevate your travel experience, whether for sightseeing, tours, or city commuting.</div>
                    <div onClick={() => navigate(URLS.ServiceClass)} className="booking-button">Book</div>
                </div>
                <div className="panel last">
                    <img className="panel-image" src={HOME_PHONE_3} alt="" />
                    <div className="title">Hourly booking</div>
                    <div className="text">Whether for business or leisure, our Hourly Booking service offers flexible, dedicated limousine service based on your unique itinerary and timing needs.</div>
                    <div onClick={() => navigate(URLS.ServiceClass)} className="booking-button">Book</div>
                </div>
            </div>
            <div className="banner-section">
                <div className="banner-content">
                    <div className="text">
                        Choose between the reliable power of our Traditional Comfort limousines, or the eco-friendly, silent luxury of our Electric Luxury limousines.
                        <br />
                        Every journey caters to your preferences, while never compromising on comfort and elegance.
                    </div>
                    <div className="images">
                        <div className="image-container">
                            <img style={{ zIndex: 5, transform: 'translate(-85%, -50%)' }} src={IMAGE_CARROUSEL_EXAMPLE_3} alt="" className="image leftCar" />
                        </div>
                        <div className="image-container">
                            <img src={BANNER_CAR} alt="" className="image rightCar" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-grid-section">
                <div className="text-grid">
                    <div className="grid-item">
                        <div className="title">Large Fleet Of Vehicles</div>
                        <div className="text">Our fleet is equipped and accessorized to the highest standards, and customized to provide standardized quality with no variations of the features or models.</div>
                    </div>
                    <div className="grid-item">
                        <div className="title">Hygiene</div>
                        <div className="text">The cars are sanitized after each transfer, using tested and specialized products that are highly microbial neutralizers with no harmful effects on health.</div>
                    </div>
                    <div className="grid-item">
                        <div className="title">Punctuality</div>
                        <div className="text">Using one of the most powerful logarithmic systems and artificial intelligence, we can anticipate heavy traffic so that the chosen route will always be the fastest to destination. The same logarithm helps us to communicate within our fleet in order to avoid unpleasant timing impacts.</div>
                    </div>
                    <div className="grid-item">
                        <div className="title">Online Booking & Payment</div>
                        <div className="text">Easy and simple booking system, with the option to change or cancel your ride, choice between online and cash payment.</div>
                    </div>
                    <div className="grid-item">
                        <div className="title">Safety</div>
                        <div className="text">We have developed two branches that will ensure that the travel experience of the guest is at the highest level, through an entirely safe atmosphere.</div>
                    </div>
                    <div className="grid-item">
                        <div className="title">Professionalism</div>
                        <div className="text">The drivers are trained towards skill development in order to offer a chauffeur - guest experience at the highest standards.</div>
                    </div>
                </div>
            </div>
            {/* <div className="download-section">
                <div className="title">Download Backhansa App</div>
                <div className="text">Book, manage, and track your limousine rides effortlessly with our intuitive mobile app. Luxury travel has never been so accessible. Available on iOS and Android.</div>
                <div className="download-button-row">
                    <img src={APPLE_DOWNLOAD_BANNER} alt="" className="download-button" />
                    <img src={GOOGLE_DOWNLOAD_BANNER} alt="" className="download-button" />
                </div>
            </div> */}
        </div>
    )
}

export default Home