import { CSSProperties, ReactNode, useState } from "react";

interface StyledTextInputProps {
    id?: string;
    children?: ReactNode;
    labelColor?: string;
    placeholder: string;
    area?: boolean;
    type?: 'text' | 'password' | 'number' | 'creditcard';
    readOnly?: boolean;
    value: string;
    defaultValue? : string;
    // setValue: (val: string) => void;
    onChange: (val: string) => void;
    onTextChange?: (text: string) => void;
    style?: CSSProperties;
}

const StyledTextInput = (props: StyledTextInputProps) => {
    const formatCreditCardNumber = (inputValue: string) => {
        if (props.type === 'creditcard') {
            const formattedValue = inputValue.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
            const parts: string[] = [];

            for (let i = 0; i < formattedValue.length; i += 4) {
                parts.push(formattedValue.substring(i, i + 4));
            }

            return parts.join(' ');
        }
        return inputValue;
    };

    return (
        <div className="styled-text-input">
            {props.children ? (
                <div className="label" style={{ color: props.labelColor + "" }}>
                    {props.children}
                </div>
            ) : (
                <></>
            )}
            {!props.area ? (
                <input
                    spellCheck={false}
                    id={props.id}
                    readOnly={props.readOnly}
                    style={props.style}
                    className="text-input"
                    placeholder={props.placeholder}
                    type={props.type ? props.type : 'text'}
                    value={formatCreditCardNumber(props.value)}
                    // defaultValue={props.defaultValue}
                    // onChange={(evt) => props.setValue(evt.target.value)}
                    onChange={(evt) => {
                        props.onTextChange?.(evt.target.value);
                        props.onChange(evt.target.value);
                    }}
                />
            ) : (
                <textarea
                    id={props.id}
                    readOnly={props.readOnly}
                    style={props.style}
                    className="text-input"
                    placeholder={props.placeholder}
                    value={props.value}
                    // onChange={(evt) => props.setValue(evt.target.value)}
                    onChange={(evt) => {
                        props.onTextChange?.(evt.target.value);
                        props.onChange(evt.target.value);
                    }}
                />
            )}
        </div>
    );
};

export default StyledTextInput;