import axios from "axios";
import TripModel from "../../models/trip";
import { AuthenticatedRequest, BasicRequest, HTTPMethod } from "../../requests";


export class API {
    public static getBookings(startAt?: number, limit?: number): Promise<Array<TripModel>> {
        return new BasicRequest<Array<TripModel>>('/api/v1/bookings', HTTPMethod.GET, undefined, undefined, startAt, limit).execute()
    }

    public static storeBooking(booking: Booking): Promise<any> {
        return new AuthenticatedRequest<any>('/api/v1/bookings', HTTPMethod.POST, undefined, booking).execute()
    }

    public static cancelBooking(booking_id: number): Promise<any> {
        return new BasicRequest<any>(`/v2/form/booking/user/reject/${booking_id}`, HTTPMethod.PUT, undefined, undefined).execute()
    }

    // public static saveBookingChanges(booking_id: number): Promise<any> {
    //     return new BasicRequest<any>(`/v4/form/booking/tripDetails/${booking_id}`, HTTPMethod.PUT, undefined, undefined).execute()
    // }
}

export interface Booking {
    pickup_address: string,
    drop_address: string,
    hours: string,
    return_journey: 'false',
    categories_id: number,
    pickup_sign: string,
    flight_number: string,
    additional_info: string,
    special_request: string,
    passagers: string,
    seats: string,
    bags: string,
    user_id: number,
    date: string,
    pickup_hour: string,
    pickup_min: string,
    type: string,
    name: string,
    email: string,
    phone: string,
    payment_method: 'Card'
}