import { BANNER_CAR, EQE, EQS, E_CLASS, MAYBACH, S_CLASS, V_CLASS } from "../../assets/exports"
import { CarrouselPanelType } from "./types"

const URLS = {
    Home: "/",
    AboutUs: "/about-us",
    ServiceClass: "/service-class",
    Contact: "/contact",
    Career: "/career",
    Profile: "/profile",
    SignIn: "/sign-in",
    SignUp: "/sign-up",
    CookiePolicy: "/cookie-policy",
    LegalNotice: "/legal-notice",
    TermsAndConditions: "/terms-and-conditions",
    PrivacyPolicy: "/privacy-policy",
}

const CLASSIC_PANELS: Array<CarrouselPanelType> = [
    {
        image: MAYBACH,
        title: 'Luxury Class',
        subtitle: 'Mercedes Benz - Maybach'
    },
    {
        image: S_CLASS,
        title: 'First Class',
        subtitle: 'Mercedes Benz - S Class Long'
    },
    {
        image: E_CLASS,
        title: 'Business Class',
        subtitle: 'Mercedes Benz - E Class'
    },
    // {
    //     image: B_CLASS,
    //     title: 'City Class',
    //     subtitle: 'Mercedes Benz - B Class'
    // },
    {
        image: V_CLASS,
        title: 'XL Class',
        subtitle: 'Mercedes Benz - V Class'
    }
]

const ELECTRIC_PANELS: Array<CarrouselPanelType> = [
    {
        image: EQS,
        title: 'Electric First Class',
        subtitle: 'Mercedes Benz - EQS'
    },
    {
        image: EQE,
        title: 'Electric Class',
        subtitle: 'Mercedes Benz - EQE'
    }
]

const GOOGLE_PLACES_BOUNDS =
    new google.maps.LatLngBounds(
        new google.maps.LatLng(51.830203, 9.283225), // SW Munich, Cologne
        new google.maps.LatLng(53.801812, 14.056784) // NE Hamburg, Berlin
    );

export {
    URLS,
    CLASSIC_PANELS,
    ELECTRIC_PANELS,
    GOOGLE_PLACES_BOUNDS
}