import axios from 'axios';
import UserModel from './models/user';
import APIUserModel from './models/api-user';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let userUpdatedHandler: ((user: UserModel) => void) | undefined = undefined;

export class Session {
  private static cachedSession: { session_token: string };
  

  static getSession(): string | undefined {
      return this.cachedSession ? this.cachedSession.session_token : undefined;
  }

  static setSession(session: any | string | undefined) {
      this.cachedSession = typeof session === 'object' ? session : { 'session_token': session }
  }

  static setUserUpdatedHandler(handler: ((user: UserModel) => void) | undefined) {
      userUpdatedHandler = handler;
  }

  static getUserUpdatedHandler() {
      return userUpdatedHandler || ((user) => { })
  }
}

export enum HTTPMethod {
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
  POST = 'POST',
  PATCH = 'PATCH'
}

/*
  Template for any axios request

  limit limits the data pagination
 */
export interface Request<T> {
  path: string;
  method: HTTPMethod;
  queryParameters?: { [key: string]: string };
  bodyParameters?: { [key: string]: any };
  startAt?: number
  limit?: number
}

export class BasicRequest<T> implements Request<T> {
  path: string;
  method: HTTPMethod;
  queryParameters?: { [key: string]: string };
  bodyParameters?: { [key: string]: any };
  startAt: number
  limit: number

    constructor(
        path: string,
        method: HTTPMethod,
        queryParameters?: { [key: string]: any },
        bodyParameters?: { [key: string]: any },
        startAt?: number,
        limit?: number
    ) {
        this.path = path;
        this.method = method;
        this.queryParameters = queryParameters;
        this.bodyParameters = bodyParameters;
        this.startAt = startAt ? startAt : 1
        this.limit = limit ? limit : 0
    }

    async execute(): Promise<T> {
        let allData: Array<T> = []
        let page = this.startAt;
        let hasMorePages = true;
      
        do {
          const { data: response } = await axios({
            method: this.method,
            url: `${process.env.REACT_APP_APP_URL}${this.path}?page=${page}`,
            params: this.queryParameters,
            data: this.bodyParameters,
            headers: { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            },
          });
      
          if (!response.collection && !response.model) {
            if(!response.data) return Promise.resolve(response as T)
            allData = [...response.data];
            break;
          }
          const currentPageData = response.collection ? response.collection.data : response.model.data;
          allData.push(...currentPageData);
      
          if (currentPageData.length === 0 || page === this.limit) {
            hasMorePages = false;
          } else {
            page++;
          }
        } while (hasMorePages);
      return Promise.resolve(allData as T)
    }
}

export class AuthenticatedRequest<T> extends BasicRequest<T> {

  constructor(
      path: string,
      method: HTTPMethod,
      queryParameters?: { [key: string]: any },
      bodyParameters?: { [key: string]: any },
      startAt?: number,
      limit?: number
  ) {
      super(path, method, queryParameters, bodyParameters, startAt, limit);
  }

  async execute(): Promise<T> {
    let allData: Array<T> = []
    let page = this.startAt;
    let hasMorePages = true;
    let localStorageUser = localStorage.getItem('user')
    let userFromData: APIUserModel | undefined = undefined
    if(localStorageUser)
      userFromData = JSON.parse(localStorageUser)

    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };

    if (userFromData) {
      headers['Authorization'] = `Bearer ${userFromData.access_token}`;
    }
    
    await axios.get(`${process.env.REACT_APP_APP_URL}/sanctum/csrf-cookie`);

    do {
      const { data: response } = await axios({
        method: this.method,
        url: `${process.env.REACT_APP_APP_URL}${this.path}?page=${page}`,
        params: this.queryParameters,
        data: this.bodyParameters,
        headers: headers
      });
  
      if (!response.collection && !response.model) {
        if(!response.data) return Promise.resolve(response as T)
        allData = [...response.data];
        break;
      }
      const currentPageData = response.collection ? response.collection.data : response.model.data;
      allData.push(...currentPageData);
  
      if (currentPageData.length === 0 || page === this.limit) {
        hasMorePages = false;
      } else {
        page++;
      }
    } while (hasMorePages);
  return Promise.resolve(allData as T)
}
}