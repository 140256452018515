import React, { useState, useEffect, useRef } from "react";
import { CHEVRON_UP } from "../../assets/exports";

interface SimpleImageCarrouselProps {
  images: string[];
}

const SimpleImageCarrousel: React.FC<SimpleImageCarrouselProps> = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const imageContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 15000); // Change slide every 15 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  const goToPrevious = () => {
    setActiveIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const goToNext = () => {
    setActiveIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };

  const handleSwipe = (event: React.TouchEvent<HTMLDivElement>) => {
    const { changedTouches } = event;
    if (changedTouches.length > 0) {
      const touchStartX = changedTouches[0].clientX;
      let hasSwiped = false;

      const handleTouchMove = (event: TouchEvent) => {
        if (hasSwiped) return;

        const touchMoveX = event.changedTouches[0].clientX;
        const deltaX = touchMoveX - touchStartX;
        const threshold = 50;

        if (Math.abs(deltaX) > threshold) {
          if (deltaX > 0) {
            goToPrevious();
          } else {
            goToNext();
          }
          hasSwiped = true;
        }
      };

      const handleTouchEnd = () => {
        document.removeEventListener("touchmove", handleTouchMove);
        document.removeEventListener("touchend", handleTouchEnd);
      };

      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleTouchEnd);
    }
  };

  return (
    <div className="simple-image-carrousel" onTouchStart={handleSwipe}>
      <div className="image-container" ref={imageContainerRef}>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index}`}
            className={`image ${index === activeIndex ? "active" : ""}`}
          />
        ))}
        {/* <div className="previous-icon" onClick={goToPrevious}>
          <img src={CHEVRON_UP} alt="" />
        </div>
        <div className="next-icon" onClick={goToNext}>
          <img src={CHEVRON_UP} alt="" />
        </div> */}
      </div>
      <div className="bubbles">
        {images.map((_, index) => (
          <div
            key={index}
            className={`bubble ${index === activeIndex ? "active" : ""}`}
            onClick={() => setActiveIndex(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default SimpleImageCarrousel;
