import { useContext, useEffect, useState } from "react";
import StyledDatePicker from "./StyledDatePicker"
import StyledTextInput from "./StyledTextInput";
import StyledTimePicker from "./StyledTimePicker";
import SwitchOption from "./SwitchOption"
import { UserContext } from "./UserContext";
import { NavLink, useNavigate } from "react-router-dom";
import { GOOGLE_PLACES_BOUNDS, URLS } from "../sdk/utils/consts";
import dayjs from "dayjs";
import StyledSelect from "./StyledSelect";
import { PopupContext } from "./PopupContext";


const ServiceForm = () => {
    const { user } = useContext(UserContext)
    let navigate = useNavigate()
    const { addPopup } = useContext(PopupContext)

    const [activeSwitchState, setActiveSwitchState] = useState<number>(0)
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(dayjs().add(1, 'day'))
    const [selectedTime, setSelectedTime] = useState<dayjs.Dayjs | null>(dayjs())
    const [pickupAddress, setPickupAddress] = useState<string>('')
    const [dropoffAddress, setDropoffAddress] = useState<string>('')
    const [hours, setHours] = useState<string>('')

    const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken | null>(null);
    const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
    const [showOriginPredictions, setShowOriginPredictions] = useState<boolean>(false)
    const [showDestinationPredictions, setShowDestinationPredictions] = useState<boolean>(false)
    const [originPredictions, setOriginPredictions] = useState<Array<google.maps.places.AutocompletePrediction>>([])
    const [destinationPredictions, setDestinationPredictions] = useState<Array<google.maps.places.AutocompletePrediction>>([])

    function passToServiceClass() {
        if (validateForm())
            navigate(`${URLS.ServiceClass}/${activeSwitchState}/${selectedDate!.format()}/${selectedTime!.format()}/${pickupAddress}/${activeSwitchState === 0 ? dropoffAddress : hours}`)
        else {
            addPopup({
                message: 'One or more fields are invalid',
                type: 'ERROR'
            })
        }
    }

    function validateForm(): boolean {
        return selectedDate != null && !selectedDate.isBefore(dayjs()) && selectedTime != null && pickupAddress.trim() != '' && (dropoffAddress.trim() != '' || hours.trim() != '')
    }

    // Init autocomplete session
    useEffect(() => {
        let token = new google.maps.places.AutocompleteSessionToken();
        setSessionToken(token);

        let service = new google.maps.places.AutocompleteService();
        setAutocompleteService(service);
    }, []);

    // Origin Input Handle
    useEffect(() => {
        if (pickupAddress && sessionToken && autocompleteService) {
            if (originPredictions.find(prediction => prediction.description === pickupAddress) === undefined)
                setShowOriginPredictions(true)
            setShowDestinationPredictions(false)
            const options = {
                input: pickupAddress,
                // types: ["address"],
                types: [],
                componentRestrictions: {
                    country: ['de'],
                },
                // locationRestriction: GOOGLE_PLACES_BOUNDS,
                sessionToken
            };

            autocompleteService.getPlacePredictions(options, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
                    setOriginPredictions(predictions)
                    console.log('Origin Predictions:', predictions);
                }
            });
        }
        if (pickupAddress.trim() === '') {
            setShowOriginPredictions(false)
            setOriginPredictions([])
        }
    }, [pickupAddress, sessionToken, autocompleteService]);

    // Destination Input Handle
    useEffect(() => {
        if (dropoffAddress && sessionToken && autocompleteService) {
            if (destinationPredictions.find(prediction => prediction.description === dropoffAddress) === undefined)
                setShowDestinationPredictions(true)
            setShowOriginPredictions(false)
            const options = {
                input: dropoffAddress,
                // types: ["address"],
                types: [],
                componentRestrictions: {
                    country: ['de'],
                },
                // locationRestriction: GOOGLE_PLACES_BOUNDS,
                sessionToken
            };

            autocompleteService.getPlacePredictions(options, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
                    setDestinationPredictions(predictions)
                    console.log('Destination Predictions:', predictions);
                }
            });
        }
        if (dropoffAddress.trim() === '') {
            setShowDestinationPredictions(false)
            setDestinationPredictions([])
        }
    }, [dropoffAddress, sessionToken, autocompleteService]);

    return (
        <div className="service-form">
            {
                !user ?
                    <div className="overlay-container">
                        <div className="text">Only authenticated users can submit form</div>
                        <NavLink to={URLS.SignIn} className="button">Sign in</NavLink>
                    </div> : <></>
            }
            <SwitchOption activeOption={activeSwitchState} setActiveOption={setActiveSwitchState} options={["Transfer", "Hourly"]} />
            <div className="row">
                <div className="input-container">
                    <StyledDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} backgroundColor="#a6c2bb" label={"Date"} labelColor={"white"} />
                </div>
                <div className="input-container">
                    <StyledTimePicker selectedTime={selectedTime} setSelectedTime={setSelectedTime} backgroundColor="#a6c2bb" label={"Time"} labelColor={"white"} />
                </div>
            </div>
            <div className="text-input-container">
                <StyledTextInput
                    style={{ borderRadius: showOriginPredictions ? '1rem 1rem 0 0' : '' }}
                    value={pickupAddress}
                    onChange={setPickupAddress}
                    labelColor={"white"}
                    placeholder="Address, airport">
                    Pickup Address
                </StyledTextInput>
                {
                    showOriginPredictions ?
                        <div className="predictions-container">
                            {
                                originPredictions.map((prediction, key) => {
                                    return (
                                        <div onClick={() => {
                                            setPickupAddress(prediction.description)
                                            setShowOriginPredictions(false)
                                        }} key={key} className="prediction">{prediction.description}</div>
                                    )
                                })
                            }
                        </div> : <></>
                }
            </div>
            {
                activeSwitchState === 0 ?
                    <div className="text-input-container">
                        <StyledTextInput
                            style={{ borderRadius: showDestinationPredictions ? '1rem 1rem 0 0' : '' }}
                            value={dropoffAddress}
                            onChange={setDropoffAddress}
                            labelColor={"white"}
                            placeholder="Address, airport"
                        >
                            Dropoff Address
                        </StyledTextInput>
                        {
                            showDestinationPredictions ?
                                <div className="predictions-container">
                                    {
                                        destinationPredictions.map((prediction, key) => {
                                            return (
                                                <div onClick={() => {
                                                    setDropoffAddress(prediction.description)
                                                    setShowDestinationPredictions(false)
                                                }} key={key} className="prediction">{prediction.description}</div>
                                            )
                                        })
                                    }
                                </div> : <></>
                        }
                    </div>
                    : <StyledSelect
                        backgroundColor="#a6c2bb"
                        inputColor='#696969'
                        labelColor={"white"}
                        options={["2 hours", "3 hours", "4 hours", "5 hours", "6 hours", "7 hours", "8 hours", "9 hours", "10 hours", "11 hours", "12 hours", "13 hours", "14 hours", "15 hours", "16 hours", "17 hours", "18 hours", "19 hours", "20 hours", "21 hours", "22 hours", "23 hours", "24 hours"]}
                        placeholder="hours"
                        value={hours}
                        setValue={setHours}
                    >
                        Hours
                    </StyledSelect>
            }
            <div onClick={() => passToServiceClass()} className="confirm-button">Book</div>
        </div>
    )
}

export default ServiceForm