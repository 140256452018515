import { useRoutes, useLocation } from "react-router-dom";
import { URLS } from "./sdk/utils/consts";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import axios from "axios";
import APIUserModel from "./sdk/api/models/api-user";
import UserModel from "./sdk/api/models/user";
import { API as LoginAPI } from './sdk/api/endpoints/login'

// Components
import Header from "./components/Header";
import Footer from "./components/Footer";
import { SidebarContextProvider } from "./components/SidebarContext";

// Pages
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Career from "./pages/Career";
import ServiceClass from "./pages/ServiceClass";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Profile from "./pages/Profile";

import Sidebar from "./components/Sidebar";
import { Session } from "./sdk/api/requests";
import { UserContextProvider } from "./components/UserContext";
import { PopupContextProvider } from "./components/PopupContext";
import PopupBounds from "./components/PopupBounds";
import { PopupType } from "./sdk/utils/types";
import Popup from "./components/Popup";
import CookiePolicy from "./pages/CookiePolicy";
import LegalNotice from "./pages/LegalNotice";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const InitialState = undefined as any

const muiTheme = createTheme({ palette: { primary: { main: '#a6c2bb' } } })
axios.defaults.withCredentials = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


function App() {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [user, setUser] = useState<UserModel>(InitialState)
  const [popups, setPopups] = useState<Array<PopupType>>([])
  const location = useLocation();

  useEffect(() => {
    // console.log('Route changed:', location.pathname);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const routes = useRoutes([
    {
      path: URLS.Home,
      element: <Home />
    },
    {
      path: URLS.AboutUs,
      element: <AboutUs />
    },
    {
      path: URLS.ServiceClass + '/:type/:date/:time/:pickupadress/:dropoffOrHours',
      element: <ServiceClass />
    },
    {
      path: URLS.ServiceClass,
      element: <ServiceClass />
    },
    {
      path: URLS.Contact,
      element: <Contact />
    },
    {
      path: URLS.Career,
      element: <Career />
    },
    {
      path: URLS.Profile,
      element: <Profile />
    },
    {
      path: URLS.SignIn,
      element: <Login />
    },
    {
      path: URLS.SignUp,
      element: <SignUp />
    },
    {
      path: URLS.CookiePolicy,
      element: <CookiePolicy />
    },
    {
      path: URLS.LegalNotice,
      element: <LegalNotice />
    },
    {
      path: URLS.TermsAndConditions,
      element: <TermsAndConditions />
    },
    {
      path: URLS.PrivacyPolicy,
      element: <PrivacyPolicy />
    },
    {
      path: '*',
      element: <Home />
    },
  ])

  useEffect(() => {
    if (sidebarOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [sidebarOpen])

  useEffect(() => {
    Session.setUserUpdatedHandler((user) => {
      setUser(user)
    })

    if (localStorage.getItem('user')) {
      try {
        let userFromData = JSON.parse(localStorage.getItem('user')!) as APIUserModel
        setUser(userFromData.user)
        LoginAPI.refreshAccessToken().then((data) => {
          setUser(data.user)
          userFromData.user = data.user
          localStorage.setItem('user', JSON.stringify(data))
        }).catch((err) => {
          LoginAPI.logout()
        })
      } catch (err) {
        console.log('no local user found')
      }
    }
  }, [])

  function addPopup(newPopup: PopupType) {
    const currentPopups = [...popups]
    currentPopups.push(newPopup)
    setPopups(currentPopups)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={muiTheme}>
        <PopupContextProvider value={{ popups: popups, addPopup: addPopup }} >
          <UserContextProvider value={{ user: user, setUser: setUser }}>
            <SidebarContextProvider value={{ open: sidebarOpen, setOpen: setSidebarOpen }}>
              <PopupBounds />
              <Header />
              <Sidebar onCloseClick={() => setSidebarOpen(!sidebarOpen)} />
              <div className="content">
                {routes}
              </div>
              <Footer />
            </SidebarContextProvider>
          </UserContextProvider>
        </PopupContextProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
