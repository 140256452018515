import ReviewModel from "../models/review";
import { AuthenticatedRequest, BasicRequest, HTTPMethod } from "../requests";


export class API {
    public static getReviews(startAt?: number, limit?: number): Promise<Array<ReviewModel>> {
        return new BasicRequest<Array<ReviewModel>>('/api/v1/reviews', HTTPMethod.GET, undefined, undefined, startAt, limit).execute()
    }

    public static submitReview(review: Review): Promise<any> {
        return new AuthenticatedRequest<any>(`/api/v1/reviews`, HTTPMethod.POST, undefined, review).execute()
    }
}

interface Review {
    booking_id: number
    user_id: number
    rating: string
    comment: string
}