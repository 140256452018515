const PrivacyPolicy = () => {
    // window.scrollTo(0, 0)
    return (
        <div className="privacy-policy">
            <div className="page-header">Privacy Policy</div>
            <div className="content">
                <div className="text">
                    <div className="text">
                        <p>
                            We are very delighted that you have shown interest in our enterprise. Data
                            protection is of a particularly high priority for the management of the
                            blackhansa GmbH. The use of the Internet pages of the blackhansa GmbH is
                            possible without any indication of personal data; however, if a data subject
                            wants to use special enterprise services via our website, processing of personal
                            data could become necessary. If the processing of personal data is necessary
                            and there is no statutory basis for such processing, we generally obtain
                            consent from the data subject.
                            The processing of personal data, such as the name, address, e-mail address, or
                            telephone number of a data subject shall always be in line with the General
                            Data Protection Regulation (GDPR), and in accordance with the country-
                            specific data protection regulations applicable to the blackhansa GmbH. By
                            means of this data protection declaration, our enterprise would like to inform
                            the general public of the nature, scope, and purpose of the personal data we
                            collect, use and process. Furthermore, data subjects are informed, by means of
                            this data protection declaration, of the rights to which they are entitled.
                            As the controller, the blackhansa GmbH has implemented numerous technical
                            and organizational measures to ensure the most complete protection of
                            personal data processed through this website. However, Internet-based data
                            transmissions may in principle have security gaps, so absolute protection may
                            not be guaranteed. For this reason, every data subject is free to transfer
                            personal data to us via alternative means, e.g. by telephone.
                        </p>
                        <h2>1. Definitions</h2>
                        <p>
                            The data protection declaration of the blackhansa GmbH is based on the terms
                            used by the European legislator for the adoption of the General Data Protection
                            Regulation (GDPR). Our data protection declaration should be legible and
                            understandable for the general public, as well as our customers and business
                            partners. To ensure this, we would like to first explain the terminology used.
                        </p>
                        <p>
                            In this data protection declaration, we use, inter alia, the following terms:
                        </p>
                        <ul>
                            <li>
                                <strong>Personal data</strong>: means any information relating to an identified or identifiable natural person
                                (“data subject”). An identifiable natural person is one who can be identified,
                                directly or indirectly, in particular by reference to an identifier such as a name,
                                an identification number, location data, an online identifier or to one or more
                                factors specific to the physical, physiological, genetic, mental, economic,
                                cultural or social identity of that natural person.
                            </li>
                            <li>
                                <strong>Data subject</strong>: is any identified or identifiable natural person, whose personal data is
                                processed by the controller responsible for the processing.
                            </li>
                            <li>
                                <strong>Processing</strong>: is any operation or set of operations which is performed on personal data or
                                sets of personal data, whether or not by automated means, such as collection,
                                recording, organization, structuring, storage, adaptation or alteration, retrieval,
                                consultation, use, disclosure by transmission, dissemination or otherwise
                                making available, alignment or combination, restriction, erasure or destruction.
                            </li>
                            <li>
                                <strong>Restriction of processing</strong>: is the marking of stored personal data with the aim of limiting their processing
                                in the future.
                            </li>
                            <li>
                                <strong>Profiling</strong>: means any form of automated processing of personal data consisting of the
                                use of personal data to evaluate certain personal aspects relating to a natural
                                person, in particular, to analyze or predict aspects concerning that natural
                                person's performance at work, economic situation, health, personal
                                preferences, interests, reliability, behavior, location or movements.
                            </li>
                            <li>
                                <strong>Pseudonymisation</strong>: is the processing of personal data in such a manner that the personal data can
                                no longer be attributed to a specific data subject without the use of additional
                                information, provided that such additional information is kept separately and is
                                subject to technical and organizational measures to ensure that the personal
                                data are not attributed to an identified or identifiable natural person.
                            </li>
                            <li>
                                <strong>Controller or controller responsible for the processing</strong>: is the natural or legal person, public authority, agency or other body which,
                                alone or jointly with others, determines the purposes and means of the
                                processing of personal data; where the purposes and means of such
                                processing are determined by Union or Member State law, the controller or the
                                specific criteria for its nomination may be provided for by Union or Member
                                State law.
                            </li>
                            <li>
                                <strong>The Processor</strong>: is a natural or legal person, public authority, agency, or other body which
                                processes personal data on behalf of the controller.
                            </li>
                            <li>
                                <strong>The Recipient</strong>: is a natural or legal person, public authority, agency or another body, to which
                                the personal data are disclosed, whether a third party or not. However, public
                                authorities which may receive personal data in the framework of a particular
                                inquiry in accordance with Union or Member State law shall not be regarded as
                                recipients; the processing of those data by those public authorities shall be in
                                compliance with the applicable data protection rules according to the purposes
                                of the processing.
                            </li>
                            <li>
                                <strong>Third party</strong>: is a natural or legal person, public authority, agency or body other than the
                                data subject, controller, processor, and persons who, under the direct authority
                                of the controller or processor, are authorized to process personal data.
                            </li>
                            <li>
                                <strong>Consent</strong>: of the data subject is any freely given, specific, informed and unambiguous
                                indication of the data subject's wishes by which he or she, by a statement or by
                                clear affirmative action, signifies agreement to the processing of personal data
                                relating to him or her.
                            </li>
                        </ul>
                        <h2>2. Name and Address of the Controller</h2>
                        <p>
                            Controller for the purposes of the General Data Protection Regulation (GDPR), other data protection laws applicable in the Member states of the European Union, and other provisions related to data protection is:
                        </p>
                        <p>
                            blackhansa GmbH<br />
                            Halensee Str. 3<br />
                            10711 Berlin<br />
                            Germany<br />
                            Phone: 01734190563<br />
                            Email: <a href="mailto:office@blackhansa.com">office@blackhansa.com</a><br />
                            Website: <a href="http://www.blackhansa.de" target="_blank" rel="noopener noreferrer">www.blackhansa.de</a>
                        </p>

                        <h2>3. Cookies</h2>
                        <p>
                            The Internet pages of blackhansa GmbH use cookies. Cookies are text files that are stored on a computer system via an Internet browser.
                        </p>
                        <p>
                            Many Internet sites and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID is a unique identifier of the cookie. It consists of a character string through which Internet pages and servers can be assigned to the specific Internet browser in which the cookie was stored. This allows visited Internet sites and servers to differentiate the individual browser of the data subject from other Internet browsers that contain other cookies. A specific Internet browser can be recognized and identified using the unique cookie ID.
                        </p>
                        <p>
                            Through the use of cookies, the blackhansa GmbH can provide the users of this website with more user-friendly services that would not be possible without the cookie setting.
                        </p>
                        <p>
                            By means of a cookie, the information and offers on our website can be optimized with the user in mind. Cookies allow us, as previously mentioned, to recognize our website users. The purpose of this recognition is to make it easier for users to utilize our website. The website user that uses cookies, e.g., does not have to enter access data each time the website is accessed, because this is taken over by the website, and the cookie is thus stored on the user's computer system. Another example is the cookie of a shopping cart in an online shop. The online store remembers the articles that a customer has placed in the virtual shopping cart via a cookie.
                        </p>
                        <p>
                            The data subject may, at any time, prevent the setting of cookies through our website by means of a corresponding setting of the Internet browser used, and may thus permanently deny the setting of cookies. Furthermore, already set cookies may be deleted at any time via an Internet browser or other software programs. This is possible in all popular Internet browsers. If the data subject deactivates the setting of cookies in the Internet browser used, not all functions of our website may be entirely usable.
                        </p>
                        <h2>4. Collection of General Data and Information</h2>
                        <p>
                            The website of blackhansa GmbH collects a series of general data and information when a data subject or automated system calls up the website. This general data and information are stored in the server log files. Collected data may include:
                        </p>
                        <ul>
                            <li>Browser types and versions used</li>
                            <li>Operating system used by the accessing system</li>
                            <li>Website from which an accessing system reaches our website (so-called referrers)</li>
                            <li>Sub-websites</li>
                            <li>Date and time of access to the Internet site</li>
                            <li>Internet Protocol address (IP address)</li>
                            <li>Internet service provider of the accessing system</li>
                            <li>Any other similar data and information that may be used in the event of attacks on our information technology systems.</li>
                        </ul>
                        <p>
                            When using this general data and information, blackhansa GmbH does not draw any conclusions about the data subject. Rather, this information is needed to:
                        </p>
                        <ol>
                            <li>Deliver the content of our website correctly</li>
                            <li>Optimize the content of our website as well as its advertisement</li>
                            <li>Ensure the long-term viability of our information technology systems and website technology</li>
                            <li>Provide law enforcement authorities with the information necessary for criminal prosecution in case of a cyber-attack.</li>
                        </ol>
                        <p>
                            Therefore, blackhansa GmbH analyzes anonymously collected data and information statistically, with the aim of increasing data protection and data security and ensuring an optimal level of protection for the personal data we process. The anonymous data from the server log files are stored separately from all personal data provided by a data subject.
                        </p>

                        <h2>5. Registration on Our Website</h2>
                        <p>
                            The data subject has the possibility to register on the website of the controller with the indication of personal data. Which personal data are transmitted to the controller is determined by the respective input mask used for registration.
                        </p>
                        <p>
                            The personal data entered by the data subject are collected and stored exclusively for internal use by the controller, and for his own purposes. The controller may request transfer to one or more processors (e.g., a parcel service) that also use personal data for an internal purpose attributable to the controller.
                        </p>
                        <p>
                            By registering on the website of the controller, the IP address—assigned by the Internet service provider (ISP) and used by the data subject—date, and time of registration are also stored. The storage of this data takes place to prevent the misuse of our services and, if necessary, to make it possible to investigate committed offenses. Insofar, the storage of this data is necessary to secure the controller. This data is not passed on to third parties unless there is a statutory obligation to pass on the data, or if the transfer serves the aim of criminal prosecution.
                        </p>
                        <p>
                            The registration of the data subject, with the voluntary indication of personal data, is intended to enable the controller to offer the data subject contents or services that may only be offered to registered users due to the nature of the matter in question. Registered persons are free to change the personal data specified during registration at any time or to have them completely deleted from the data stock of the controller.
                        </p>
                        <p>
                            The data controller shall, at any time, provide information upon request to each data subject as to what personal data are stored about the data subject. In addition, the data controller shall correct or erase personal data at the request or indication of the data subject, insofar as there are no statutory storage obligations. The entirety of the controller’s employees is available to the data subject in this respect as contact persons.
                        </p>
                        <h2>6. Subscription to Our Newsletters</h2>
                        <p>
                            On the website of blackhansa GmbH, users are given the opportunity to subscribe to our enterprise's newsletter. The input mask used for this purpose determines what personal data are transmitted, as well as when the newsletter is ordered from the controller.
                            The blackhansa GmbH informs its customers and business partners regularly by means of a newsletter about enterprise offers. The enterprise's newsletter may only be received by the data subject if (1) The data subject has a valid e-mail address (2) The data subject registers for the newsletter shipping.
                            A confirmation e-mail will be sent to the e-mail address registered by a data subject for the first time for newsletter shipping, for legal reasons, in the double-opt-in procedure. This confirmation e-mail is used to prove whether the owner of the e-mail address as the data subject is authorized to receive the newsletter.
                        </p>
                        <p>
                            During the registration for the newsletter, we also store the IP address of the computer system assigned by the Internet service provider (ISP) and used by the data subject at the time of the registration, as well as the date and time of the registration. The collection of this data is necessary in order to understand the (possible) misuse of the e-mail address of a data subject at a later date and it, therefore, serves the aim of the legal protection of the controller.
                        </p>
                        <p>
                            The personal data collected as part of registration for the newsletter will only be used to send our newsletter. In addition, subscribers to the newsletter may be informed by e-mail, as long as this is necessary for the operation of the newsletter service or a registration in question. There will be no transfer of personal data collected by the newsletter service to third parties. The subscription to our newsletter may be terminated by the data subject at any time. The consent to the storage of personal data, which the data subject has given for shipping the newsletter, may be revoked at any time. For the purpose of revocation of consent, a corresponding link is found in each newsletter. It is also possible to unsubscribe from the newsletter at any time directly on the website of the controller or to communicate this to the controller in a different way.
                        </p>

                        <h2>7. Newsletter-Tracking</h2>
                        <p>
                            The newsletter of blackhansa GmbH contains so-called tracking pixels. A tracking pixel is a miniature graphic embedded in such e-mails, which are sent in HTML format to enable log file recording and analysis. This allows a statistical analysis of the success or failure of online marketing campaigns.
                            Based on the embedded tracking pixel, the blackhansa GmbH may see if and when an e-mail was opened by a data subject, and which links in the e-mail were called up by data subjects.
                            Such personal data collected in the tracking pixels contained in the newsletters are stored and analyzed by the controller to optimize the shipping of the newsletter, as well as to adapt the content of future newsletters even better to the interests of the data subject. These personal data will not be passed on to third parties. Data subjects are at any time entitled to revoke the respective separate declaration of consent issued by means of the double-opt-in procedure. After a revocation, these personal data will be deleted by the controller. The blackhansa GmbH automatically regards a withdrawal from the receipt of the newsletter as a revocation.
                        </p>

                        <h2>8. Contact Possibility via the Website</h2>
                        <p>
                            The website of blackhansa GmbH contains information that enables quick electronic contact with our enterprise, as well as direct communication with us, which also includes a general address of the so-called electronic mail (e-mail address). If a data subject contacts the controller by e-mail or via a contact form, the personal data transmitted by the data subject are automatically stored. Such personal data transmitted voluntarily by a data subject to the data controller are stored for the purpose of processing or contacting the data subject. There is no transfer of this personal data to third parties.
                        </p>

                        <h2>9. Comments Function in the Blog on the Website</h2>
                        <p>
                            The blackhansa GmbH offers users the possibility to leave individual comments on individual blog contributions on a blog, which is on the website of the controller. A blog is a web-based, publicly-accessible portal, through which one or more people called bloggers or web-bloggers may post articles or write down thoughts in so-called blogposts. Blogposts may usually be commented on by third parties.
                        </p>
                        <p>
                            If a data subject leaves a comment on the blog published on this website, the comments made by the data subject are also stored and published, as well as information on the date of the commentary and the user's (pseudonym) chosen by the data subject. In addition, the IP address assigned by the Internet service provider (ISP) to the data subject is also logged. This storage of the IP address takes place for security reasons, and in case the data subject violates the rights of third parties or posts illegal content through a given comment. The storage of these personal data is, therefore, in the own interest of the data controller, so that he can exculpate in the event of an infringement. This collected personal data will not be passed to third parties unless such a transfer is required by law or serves the aim of the defense of the data controller.
                        </p>
                        <h2>10. Subscription to comments in the blog on the website</h2>
                        <p>
                            The comments made in the blog of the blackhansa GmbH may be subscribed to
                            by third parties. In particular, there is the possibility that a commenter
                            subscribes to the comments following his comments on a particular blog post.
                            If a data subject decides to subscribe to the option, the controller will send an
                            automatic confirmation e-mail to check the double opt-in procedure as to
                            whether the owner of the specified e-mail address decided in favor of this
                            option. The option to subscribe to comments may be terminated at any time.
                        </p>
                        <h2>11. Routine erasure and blocking of personal data</h2>
                        <p>
                            The data controller shall process and store the personal data of the data
                            subject only for the period necessary to achieve the purpose of storage, or as
                            far as this is granted by the European legislator or other legislators in laws or
                            regulations to which the controller is subject to.
                            If the storage purpose is not applicable, or if a storage period prescribed by the
                            European legislator or another competent legislator expires, the personal data
                            are routinely blocked or erased in accordance with legal requirements.
                        </p>
                        <h2>12. Rights of the data subject</h2>
                        <p>
                            <ul>
                                <li>Right of confirmation
                                    each data subject shall have the right granted by the European legislator to
                                    obtain from the controller the confirmation as to whether or not personal data
                                    concerning him or her are being processed. If a data subject wishes to avail
                                    himself of this right of confirmation, he or she may, at any time, contact any
                                    employee of the controller.
                                </li>
                                <li>Right of access
                                    each data subject shall have the right granted by the European legislator to
                                    obtain from the controller free information about his or her personal data stored
                                    at any time and a copy of this information. Furthermore, the European
                                    directives and regulations grant the data subject access to the following
                                    information:
                                    <ol>
                                        <li>
                                            the purposes of the processing;
                                        </li>
                                        <li>
                                            the categories of personal data concerned;
                                        </li>
                                        <li>
                                            the recipients or categories of recipients to whom the personal data
                                            have been or will be disclosed, in particular recipients in third
                                            countries or international organizations;
                                        </li>
                                        <li>
                                            where possible, the envisaged period for which the personal data will
                                            be stored, or, if not possible, the criteria used to determine that
                                            period;
                                        </li>
                                        <li>
                                            the existence of the right to request from the controller rectification or
                                            erasure of personal data, or restriction of processing of personal data
                                            concerning the data subject, or to object to such processing;
                                        </li>
                                        <li>
                                            the existence of the right to request from the controller rectification or
                                            erasure of personal data, or restriction of processing of personal data
                                            concerning the data subject, or to object to such processing;
                                        </li>
                                        <li>
                                            the existence of the right to file a complaint with a supervisory
                                            authority;
                                        </li>
                                        <li>
                                            where the personal data are not collected from the data subject, any
                                            available information as to their source;
                                        </li>
                                        <li>
                                            the existence of automated decision-making, including profiling,
                                            referred to in Article 22(1) and (4) of the GDPR and, at least in those
                                            cases, meaningful information about the logic involved, as well as the
                                            significance and envisaged consequences of such processing for the
                                            data subject.
                                        </li>
                                    </ol>
                                </li>
                                <li>Furthermore, the data subject shall have a right to obtain information as to
                                    whether personal data are transferred to a third country or an international
                                    organization. Where this is the case, the data subject shall have the right to be
                                    informed of the appropriate safeguards relating to the transfer.
                                    If a data subject wishes to avail himself of this right of access, he or she may,
                                    at any time, contact any employee of the controller.
                                </li>
                                <li>Right to rectification:
                                    each data subject shall have the right granted by the European legislator to
                                    obtain from the controller without undue delay the rectification of inaccurate
                                    personal data concerning him or her. Taking into account the purposes of the
                                    processing, the data subject shall have the right to have incomplete personal
                                    data completed, including by means of providing a supplementary statement.
                                    If a data subject wishes to exercise this right to rectification, he or she may, at
                                    any time, contact any employee of the controller.
                                </li>
                                <li>Right to erasure (Right to be forgotten):
                                    each data subject shall have the right granted by the European legislator to
                                    obtain from the controller the erasure of personal data concerning him or her
                                    without undue delay, and the controller shall have the obligation to erase
                                    personal data without undue delay where one of the following grounds applies,
                                    as long as the processing is not necessary:
                                    The personal data are no longer necessary in relation to the purposes
                                    for which they were collected or otherwise processed.
                                    The data subject withdraws consent to which the processing is based
                                    according to point (a) of Article 6(1) of the GDPR, or point (a) of
                                    Article 9(2) of the GDPR, and where there is no other legal ground for
                                    the processing.
                                    The data subject objects to the processing according to Article 21(1)
                                    of the GDPR and there are no overriding legitimate grounds for the
                                    processing, or the data subject objects to the processing according to
                                    Article 21(2) of the GDPR.
                                    The personal data have been unlawfully processed.
                                    The personal data must be erased for compliance with a legal
                                    obligation in Union or Member State law to which the controller is
                                    subject.
                                    The personal data have been collected in relation to the offer of
                                    information society services referred to in Article 8(1) of the GDPR.
                                </li>
                                <li>If one of the aforementioned reasons applies, and a data subject wishes to
                                    request the erasure of personal data stored by the blackhansa GmbH, he or she
                                    may, at any time, contact any employee of the controller. An employee of
                                    blackhansa GmbH shall promptly ensure that the erasure request is complied
                                    with immediately.
                                    Where the controller has made personal data public and is obliged according
                                    to Article 17(1) to erase the personal data, the controller, taking account of
                                    available technology and the cost of implementation, shall take reasonable
                                    steps, including technical measures, to inform other controllers processing the
                                    personal data that the data subject has requested the erasure by such
                                    controllers of any links to, or copy or replication of, those personal data, as far
                                    as processing is not required. An employee of the blackhansa GmbH will
                                    arrange the necessary measures in individual cases.
                                </li>

                                <li>Right of restriction of processing:
                                    each data subject shall have the right granted by the European legislator to
                                    obtain from the controller restriction of processing where one of the following
                                    applies:
                                    <ol>
                                        <li>
                                            The accuracy of the personal data is contested by the data subject,
                                            for a period enabling the controller to verify the accuracy of the
                                            personal data.
                                        </li>
                                        <li>
                                            The processing is unlawful and the data subject opposes the erasure
                                            of the personal data and requests the restriction of their use instead.
                                        </li>
                                        <li>
                                            The controller no longer needs the personal data for the purposes of
                                            the processing, but they are required by the data subject for the
                                            establishment, exercise, or defense of legal claims.
                                        </li>
                                        <li>
                                            The data subject has objected to processing according to Article 21(1)
                                            of the GDPR pending the verification of whether the legitimate
                                            grounds of the controller override those of the data subject.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    If one of the aforementioned conditions is met, and a data subject wishes to
                                    request the restriction of the processing of personal data stored by the
                                    blackhansa GmbH, he or she may at any time contact any employee of the
                                    controller. The employee of the blackhansa GmbH will arrange the restriction of
                                    the processing.
                                </li>
                                <li>Right to data portability:
                                    each data subject shall have the right granted by the European legislator, to
                                    receive the personal data concerning him or her, which was provided to a
                                    controller, in a structured, commonly used, and machine-readable format. He or
                                    she shall have the right to transmit those data to another controller without
                                    hindrance from the controller to which the personal data have been provided,
                                    as long as the processing is based on consent according to point (a) of Article
                                    6(1) of the GDPR or point (a) of Article 9(2) of the GDPR, or on a contract
                                    according to point (b) of Article 6(1) of the GDPR, and the processing is carried
                                    out by automated means, as long as the processing is not necessary for the
                                    performance of a task carried out in the public interest or in the exercise of
                                    official authority vested in the controller.
                                    Furthermore, in exercising his or her right to data portability according to
                                    Article 20(1) of the GDPR, the data subject shall have the right to have personal
                                    data transmitted directly from one controller to another, where technically
                                    feasible and when doing so does not adversely affect the rights and freedoms
                                    of others.
                                    In order to assert the right to data portability, the data subject may at any time
                                    contact any employee of the blackhansa GmbH.
                                </li>
                                <li>
                                    Right to object:
                                    each data subject shall have the right granted by the European legislator to
                                    object, on grounds relating to his or her particular situation, at any time, to
                                    processing of personal data concerning him or her, which is based on point (e)
                                    or (f) of Article 6(1) of the GDPR. This also applies to profiling based on these
                                    provisions.
                                    The blackhansa GmbH shall no longer process the personal data in the event
                                    of the objection unless we can demonstrate compelling legitimate grounds for
                                    the processing which override the interests, rights, and freedoms of the data
                                    subject, or for the establishment, exercise or defense of legal claims.
                                    If the blackhansa GmbH processes personal data for direct marketing
                                    purposes, the data subject shall have the right to object at any time to
                                    processing of personal data concerning him or her for such marketing. This
                                    applies to profiling to the extent that it is related to such direct marketing. If the
                                    data subject objects to the blackhansa GmbH to the processing for direct
                                    marketing purposes, the blackhansa GmbH will no longer process the personal
                                    data for these purposes.
                                    In addition, the data subject has the right, on grounds relating to his or her
                                    particular situation, to object to the processing of personal data concerning him
                                    or her by the blackhansa GmbH for scientific or historical research purposes, or
                                    for statistical purposes pursuant to Article 89(1) of the GDPR, unless the
                                    processing is necessary for the performance of a task carried out for reasons
                                    of public interest.
                                    In order to exercise the right to object, the data subject may contact any
                                    employee of the blackhansa GmbH. In addition, the data subject is free in the
                                    context of the use of information society services, and notwithstanding
                                    Directive 2002/58/EC, to use his or her right to object by automated means
                                    using technical specifications.
                                </li>
                                <li>
                                    Automated individual decision-making, including profiling: each data subject
                                    shall have the right granted by the European legislator not to be subject to a
                                    decision based solely on automated processing, including profiling, which
                                    produces legal effects concerning him or her, or similarly significantly affects
                                    him or her, as long as the decision (1) is not is necessary for entering into, or
                                    the performance of, a contract between the data subject and a data controller,
                                    or (2) is not authorized by Union or Member State law to which the controller is
                                    subject and which also lays down suitable measures to safeguard the data
                                    subject's rights and freedoms and legitimate interests, or (3) is not based on
                                    the data subject's explicit consent.
                                    If the decision (1) is necessary for entering into, or the performance of, a
                                    contract between the data subject and a data controller, or (2) it is based on
                                    the data subject's explicit consent, the blackhansa GmbH shall implement
                                    suitable measures to safeguard the data subject's rights and freedoms and
                                    legitimate interests, at least the right to obtain human intervention on the part
                                    of the controller, to express his or her point of view and contest the decision.
                                    If the data subject wishes to exercise the rights concerning automated
                                    individual decision-making, he or she may, at any time, contact any employee
                                    of the blackhansa GmbH.
                                </li>
                                <li>Right to withdraw data protection consent:
                                    each data subject shall have the right granted by the European legislator to
                                    withdraw his or her consent to the processing of his or her personal data at any
                                    time.
                                    If the data subject wishes to exercise the right to withdraw the consent, he or
                                    she may, at any time, contact any employee of the blackhansa GmbH.
                                </li>
                            </ul>
                        </p>
                        <h2>13. Data protection for applications and the application procedures</h2>
                        <p>The data controller shall collect and process the personal data of applicants for
                            the purpose of the processing of the application procedure. The processing
                            may also be carried out electronically. This is the case, in particular, if an
                            applicant submits corresponding application documents by e-mail or by means
                            of a web form on the website to the controller. If the data controller concludes
                            an employment contract with an applicant, the submitted data will be stored for
                            the purpose of processing the employment relationship in compliance with
                            legal requirements. If no employment contract is concluded with the applicant
                            by the controller, the application documents shall be automatically erased two
                            months after notification of the refusal decision, provided that no other
                            legitimate interests of the controller are opposed to the erasure. Another
                            legitimate interest in this relation is, e.g. a burden of proof in a procedure under
                            the General Equal Treatment Act (AGG).
                        </p>
                        <h2>14. Legal basis for the processing</h2>
                        <p>Art. 6(1) lit. a GDPR serves as the legal basis for processing operations for
                            which we obtain consent for a specific processing purpose. If the processing of
                            personal data is necessary for the performance of a contract to which the data
                            subject is party, as is the case, for example, when processing operations are
                            necessary for the supply of goods or to provide any other service, the
                            processing is based on Article 6(1) lit. b GDPR. The same applies to such
                            processing operations which are necessary for carrying out pre-contractual
                            measures, for example in the case of inquiries concerning our products or
                            services. Is our company subject to a legal obligation by which processing of
                            personal data is required, such as for the fulfillment of tax obligations, the
                            processing is based on Art. 6(1) lit. c GDPR. In rare cases, the processing of
                            personal data may be necessary to protect the vital interests of the data
                            subject or of another natural person. This would be the case, for example, if a
                            visitor were injured in our company and his name, age, health insurance data, or
                            other vital information would have to be passed on to a doctor, hospital, or
                            other third parties. Then the processing would be based on Art. 6(1) lit. d
                            GDPR. Finally, processing operations could be based on Article 6(1) lit. f GDPR.
                            This legal basis is used for processing operations which are not covered by any
                            of the abovementioned legal grounds, if the processing is necessary for the
                            purposes of the legitimate interests pursued by our company or by a third
                            party, except where such interests are overridden by the interests or
                            fundamental rights and freedoms of the data subject which require protection
                            of personal data. Such processing operations are particularly permissible
                            because they have been specifically mentioned by the European legislator. He
                            considered that a legitimate interest could be assumed if the data subject is a
                            client of the controller (Recital 47 Sentence 2 GDPR).
                        </p>
                        <h2>15. The legitimate interests pursued by the controller or by a third party</h2>
                        <p>
                            Where the processing of personal data is based on Article 6(1) lit. f GDPR our
                            legitimate interest is to carry out our business in favor of the well-being of all
                            our employees and the shareholders.
                        </p>
                        <h2>16. Period for which the personal data will be stored</h2>
                        <p>The criteria used to determine the period of storage of personal data is the
                            respective statutory retention period. After the expiration of that period, the
                            corresponding data is routinely deleted, as long as it is no longer necessary for
                            the fulfillment of the contract or the initiation of a contract.</p>
                        <h2>17. Provision of personal data as a statutory or contractual requirement;
                            Requirement necessary to enter into a contract; Obligation of the data
                            subject to provide the personal data; possible consequences of failure to
                            provide such data
                        </h2>
                        <p>We clarify that the provision of personal data is partly required by law (e.g. tax
                            regulations) or can also result from contractual provisions (e.g. information on
                            the contractual partner). Sometimes it may be necessary to conclude a
                            contract that the data subject provides us with personal data, which must
                            subsequently be processed by us. The data subject is, for example, obliged to
                            provide us with personal data when our company signs a contract with him or
                            her. The non-provision of the personal data would have the consequence that
                            the contract with the data subject could not be concluded. Before personal
                            data is provided by the data subject, the data subject must contact any
                            employee. The employee clarifies to the data subject whether the provision of
                            the personal data is required by law or contract or is necessary for the
                            conclusion of the contract, whether there is an obligation to provide the
                            personal data, and the consequences of non-provision of the personal data.
                        </p>
                        <h2>18. Existence of automated decision-making</h2>
                        <p>As a responsible company, we do not use automatic decision-making or
                            profiling.</p>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default PrivacyPolicy
