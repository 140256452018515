import { useState } from "react"
import StyledCheckbox from "../components/StyledCheckbox"
import StyledTextInput from "../components/StyledTextInput"


const Career = () => {
    const [checkboxState, setCheckboxState] = useState<boolean>(false)
    const [name, setName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [subject, setSubject] = useState<string>('')
    const [message, setMessage] = useState<string>('')

    return (
        <div className="career">
            <div className="page-header">Career</div>
            <div className="content">
                <div className="text-container">
                    <div className="title">Join Our Team</div>
                    <div className="text">We're always looking for dedicated, professional chauffeurs to join our team. If you have a passion for providing exceptional service and a commitment to ensuring the comfort and safety of our clients, we'd love to hear from you.</div>
                    <div className="text">As part of our team, you'll have the opportunity to work with a diverse range of clients and drive some of the most luxurious vehicles on the market. We offer competitive compensation, comprehensive training, and a respectful, inclusive work environment.</div>
                    <div className="text">Whether you're an experienced chauffeur looking for a new opportunity or you're seeking a rewarding new career, consider applying to join our team of professional drivers.</div>
                    <div className="text">To apply, please fill out the contact form below. Include your contact information, details of any relevant driving experience, and a brief explanation of why you're interested in working with us.</div>
                    <div className="text">We look forward to hearing from you and potentially welcoming you to our team.</div>
                    {/* <div className="list-container">
                        <div className="list-element"><div className="square" /><div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloreconsectetur adipiscing.</div></div>
                        <div className="list-element"><div className="square" /><div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloreconsectetur adipiscing.</div></div>
                        <div className="list-element"><div className="square" /><div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloreconsectetur adipiscing.</div></div>
                        <div className="list-element"><div className="square" /><div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloreconsectetur adipiscing.</div></div>
                        <div className="list-element"><div className="square" /><div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloreconsectetur adipiscing.</div></div>
                        <div className="list-element"><div className="square" /><div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et doloreconsectetur adipiscing.</div></div>
                    </div> */}
                </div>
                <div className="form">
                    <StyledTextInput value={name} onChange={setName} style={{ backgroundColor: "#f5f5f5", color: 'black' }} placeholder="">Name *</StyledTextInput>
                    <StyledTextInput value={email} onChange={setEmail} style={{ backgroundColor: "#f5f5f5", color: 'black' }} placeholder="">Email *</StyledTextInput>
                    <StyledTextInput value={subject} onChange={setSubject} style={{ backgroundColor: "#f5f5f5", color: 'black' }} placeholder="">Subject *</StyledTextInput>
                    <StyledTextInput value={message} onChange={setMessage} area style={{ backgroundColor: "#f5f5f5", color: 'black' }} placeholder="">Message</StyledTextInput>
                    <div className="checkbox-container">
                        <StyledCheckbox active={checkboxState} setActive={setCheckboxState}>
                            <div className="checkbox-text">I accept the instructions in the <b>Privacy Policy</b><span className="star">*</span></div>
                        </StyledCheckbox>
                    </div>
                    <div className="button-row">
                        <div className="attach-button">Attach Files</div>
                        <div className="submit-button">Submit</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Career