import APIUserModel from "../models/api-user";
import UserModel from "../models/user";
import { AuthenticatedRequest, HTTPMethod, Session } from "../requests";
import { UserContext } from "../../../components/UserContext";


export class API {

    public static login(email: string, password: string): Promise<APIUserModel> {
        return new AuthenticatedRequest<APIUserModel>(`/api/auth/login`, HTTPMethod.POST, undefined, {
            email,
            password
        }).execute()
    }

    public static register(name: string, email: string, password: string): Promise<any> {
        return new AuthenticatedRequest<any>(`/api/auth/register`, HTTPMethod.POST, undefined, {
            'name': name,
            'email': email,
            'password': password
        }).execute()
    }

    public static getUser(): Promise<{
        user: UserModel
    }> {
        let storedUser = JSON.parse(localStorage.getItem('user')!) as APIUserModel
        return new AuthenticatedRequest<{
            user: UserModel
        }>(`/api/v1/users/${storedUser.user.id}`, HTTPMethod.GET, undefined, undefined).execute()
    }

    public static editCurrentUser(bodyParams: EdditRequest): Promise<any> {
        let storedUser = JSON.parse(localStorage.getItem('user')!) as APIUserModel
        console.log('User ID: '+storedUser.user.id)
        return new AuthenticatedRequest<any>(`/api/v1/users/${storedUser.user.id}`, HTTPMethod.PUT, undefined, bodyParams).execute()
    }

    public static refreshAccessToken(): Promise<APIUserModel> {
        return new AuthenticatedRequest<APIUserModel>(`/api/auth/refresh`, HTTPMethod.POST, undefined, undefined).execute()
    }

    public static logout() {
        Session.setSession(undefined)
        localStorage.setItem('user', '')
        Session.getUserUpdatedHandler()(undefined as any)
    }

    /* 
        Might be an API Error, /me is only set as a POST request
    */
    // public static getUser(): Promise<any> {
    //     return new AuthenticatedRequest<any>('/api/auth/me', HTTPMethod.POST, undefined, undefined).execute()
    // }
}

interface EdditRequest {
    name?: string
    email?: string
    password?: string
}