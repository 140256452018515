import { useContext, useRef, useState } from "react"
import StyledTextInput from "../components/StyledTextInput"
import validator from "validator"
import { API as ContactFormAPI } from '../sdk/api/endpoints/contact-form'
import { PopupContext } from "../components/PopupContext"
import ReCAPTCHA from "react-google-recaptcha"
import { FACEBOOK_ICON, INSTAGRAM_ICON, YOUTUBE_ICON, LINKEDIN_ICON } from "../assets/exports"

/* 

    A captcha is setup using reCAPTCHA v2. Requests have to be send using blackhansa backend to comply with CORS restrictions.
    Until the backend is adjusted this is commented out

*/

const Contact = () => {
    const { addPopup } = useContext(PopupContext)
    const [name, setName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [subject, setSubject] = useState<string>('')
    const [message, setMessage] = useState<string>('')
    //const captchaRef = useRef<ReCAPTCHA>(null);

    // function postForm() {
    //     if(validate()) {
    //         const token = captchaRef.current ? captchaRef.current.getValue() : null;
    //         if (!token) {
    //             addPopup({
    //                 message: 'Please complete the reCAPTCHA challenge.',
    //                 type: 'ERROR'
    //             });
    //             return;
    //         }

    //         validateRecaptchaToken(token)
    //             .then((response) => {
    //                 if (response.success) {
    //                     return ContactFormAPI.postContactForm({
    //                         name: name,
    //                         email: email,
    //                         subject: subject,
    //                         message: message
    //                     });
    //                 } else {
    //                     throw new Error("reCAPTCHA verification failed.");
    //                 }
    //             })
    //             .then(() => {
    //                 addPopup({
    //                     message: 'Vielen Dank! Wir werden uns um Ihre Anfrage kümmern.',
    //                     type: 'OK'
    //                 })
    //             })
    //             .catch((err) => {
    //                 addPopup({
    //                     message: 'Ein Fehler ist aufgetreten',
    //                     type: 'ERROR'
    //                 })
    //             })
    //             .finally(() => {
    //                 //cleanUp()
    //             });
    //     }
    // }

    function postForm() {
        if (validate()) {
            ContactFormAPI.postContactForm({
                name: name,
                email: email,
                subject: subject,
                message: message
            }).then(() => {
                addPopup({
                    message: 'Vielen Dank! Wir werden uns um Ihre Anfrage kümmern.',
                    type: 'OK'
                })
            }).catch((err) => {
                addPopup({
                    message: 'Ein Fehler ist aufgetreten',
                    type: 'ERROR'
                })
            }).finally(() => {
                cleanUp()
            })
        }
    }

    function validateRecaptchaToken(token: string) {
        const recaptchaVerificationURL = `https://www.google.com/recaptcha/api/siteverify?secret=${process.env.REACT_APP_CAPTCHA_SECRET_KEY}&response=${token}`;

        return fetch(recaptchaVerificationURL)
            .then(response => response.json())
            .catch(err => {
                console.error('Error verifying reCAPTCHA token:', err);
                return { success: false };
            });
    }

    function cleanUp() {
        setName('')
        setEmail('')
        setSubject('')
        setMessage('')
    }

    function validate(): boolean {
        return name.trim() !== '' && validator.isEmail(email) && subject.trim() !== '' && message.trim() !== ''
    }

    return (
        <div className="contact">
            <div className="page-header">Contact</div>
            <div className="content">
                <div className="detail-row">
                    <div className="detail-container">
                        <div className="title">Contact details</div>
                        <div className="link-container">
                            <a target="_blank" href="https://goo.gl/maps/dKqAFJ8hy3wjbEe47" style={{ width: '100%' }} className="link"><div className="square" />Halensee Str. 3 10711 Berlin</a>
                            <a href="tel:+49 1734190563" className="link"><span className="square" />+49 1734190563</a>
                            <a href="mailto:office@blackhansa.com" className="link"><span className="square" />office@blackhansa.com</a>
                        </div>
                    </div>
                    <div className="detail-container">
                        <div className="title">Social media</div>
                        <div className="link-container">
                            <div className="link"><span className="square" />
                                <a href="https://www.linkedin.com/company/blackhansa" target="_blank" rel="noopener noreferrer" className="link">
                                    <img src={LINKEDIN_ICON} alt="LinkedIn" className="icon" />
                                </a>
                            </div>
                            {/* <div className="link"><span className="square" />
                                <img src={INSTAGRAM_ICON} alt="Instagram" className="icon" />
                            </div> */}
                            <div className="link"><span className="square" />
                                <a href="https://www.youtube.com/channel/UCCcba_Cb6aVrhZ7WKvyq0dQ" target="_blank" rel="noopener noreferrer" className="link">
                                    <img src={YOUTUBE_ICON} alt="YouTube" className="icon" />
                                </a>
                            </div>
                            <div className="link"><span className="square" />
                                <a href="https://www.facebook.com/blackhansaHQ/" target="_blank" rel="noopener noreferrer" className="link">
                                    <img src={FACEBOOK_ICON} alt="Facebook" className="icon" />
                                </a>
                            </div>


                        </div>
                    </div>
                    <div className="detail-container">
                        <div className="title">Get in touch</div>
                        <div className="text">
                            We are here to answer your questions.<br />Please let us know below how we may assist you.
                        </div>
                    </div>
                </div>
                <div className="bottom-row">
                    <iframe
                        className="map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2429.116047479947!2d13.284433000000002!3d52.4951389!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a850c891f7f253%3A0xc6c2f02558e48733!2sHalenseestra%C3%9Fe%203%2C%2010711%20Berlin!5e0!3m2!1sen!2sde!4v1687134139792!5m2!1sen!2sde"
                        style={{ border: 0 }}
                        loading="lazy" />
                    <div className="form">
                        <StyledTextInput value={name} onChange={setName} style={{ backgroundColor: "#f5f5f5", color: 'black' }} placeholder="">Name *</StyledTextInput>
                        <StyledTextInput value={email} onChange={setEmail} style={{ backgroundColor: "#f5f5f5", color: 'black' }} placeholder="">Email *</StyledTextInput>
                        <StyledTextInput value={subject} onChange={setSubject} style={{ backgroundColor: "#f5f5f5", color: 'black' }} placeholder="">Subject *</StyledTextInput>
                        <StyledTextInput value={message} onChange={setMessage} area style={{ backgroundColor: "#f5f5f5", color: 'black' }} placeholder="">Message</StyledTextInput>
                        {/* <div className="captcha-container">
                            <ReCAPTCHA sitekey={''+process.env.REACT_APP_CAPTCHA_SITE_KEY} ref={captchaRef}  />
                        </div> */}
                        <div onClick={() => postForm()} className="submit-button">Submit</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact